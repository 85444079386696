import React, { Suspense, useEffect, useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer'
import { logout } from 'store/modules/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from "store/modules/global";
import useUser from "../../hooks/fetch/useUser";
import useIntercom from "hooks/useIntercom";
import useMakeRequest from "hooks/useMakeRequest";
import { editProfile } from "store/modules/user/actions";
import NavBar from "../NavBar/NavBar";
import Spinner from "../Spinner"
import { messaging } from "../../firebase";
import { getToken, onMessage } from "firebase/messaging";
import usePayment from "hooks/fetch/usePayment";
import useCard from "hooks/fetch/useCard";
import RequireAuth from "HOC/RequireAuth";

export default function AppLayout() {
    let dispatch = useDispatch()
    const { makeDispatch } = useMakeRequest()
    const { pathname } = useLocation()
    const { boot } = useIntercom()
    const { get_banners, fetch_profile, get_wallet_balance } = useUser()
    const { get_virtual_cards } = useCard()
    const { get_env } = usePayment()
    const { loggedUser, token } = useSelector(state => state.auth)
    const { profile } = useSelector(state => state.user)

    const loadComponents = async () => {
        await import('pages/Account/index.js');
        await import('pages/Transaction/Transaction.js');
        await import('pages/Cards/VirtualCard.js');
        await import('pages/Cards/CardDetails.js');
        await import('pages/Utilities/Airtime.js');
        await import('pages/Utilities/Data.js');
        await import('pages/Utilities/Cable.js');
        await import('pages/Utilities/Electricity.js');
    }

    const getFirebaseToken = async () => {
        const status = await Notification.requestPermission()
        if (status === 'granted') {
            //getting token from FCM
            const fcm_token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY })
            if (fcm_token) return makeDispatch({ action: editProfile({ payload: { fcm_token } }), alert: false })
        }
    }

    // Logout user when idle for 5 minutes
    const onIdle = () => dispatch(logout())
    const { start } = useIdleTimer({ onIdle, timeout: 1000 * 60 * 5 })
    useEffect(() => { start() }, [])

    useLayoutEffect(() => {
        window.scroll(0, 0)
    }, [pathname])

    useEffect(() => {
        if (token) {
            Promise.all([
                fetch_profile(),
                get_virtual_cards(loggedUser?.user_id),
                get_banners(),
                get_env()
            ])
        }
    }, [token])

    useEffect(() => {
        if (profile?.tier_levels > 1) get_wallet_balance()
    }, [profile?.tier_levels])

    useEffect(() => {
        if (profile?.email) {
            boot()
            getFirebaseToken()
            loadComponents()
        }
    }, [profile?.email])

    useEffect(() => {
        if ('serviceWorker' in navigator && profile?.email) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .catch((err) => err)
        }
    }, [profile?.email])

    useEffect(() => {
        if (profile?.email) {
            onMessage(messaging, (payload) => {
                dispatch(notify({ display: true, status: 'notify', message: payload.notification.title }))
            })
        }
    })

    return (
        <RequireAuth>
            <div className="pb-[70px] overflow-x-hidden h-[100vh]">
                <NavBar />
                <div className="w-full app_container px-[16px] sm:px-[60px] md:px-[16px] pt-[2rem] lg:pt-0 lg:mt-[8rem] min-h-[75.5vh]">
                    <Suspense fallback={<Spinner />}>
                        <Outlet />
                    </Suspense>
                </div>
            </div>
        </RequireAuth>
    )
}