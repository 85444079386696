import React from "react"
import PropTypes from "prop-types"

export default function Notification({ status }) {
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer">
            <circle cx="20" cy="20" r="20" fill="#F7F7F8"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20 25.8476C25.6392 25.8476 28.2481 25.1242 28.5 22.2205C28.5 19.3188 26.6812 19.5054 26.6812 15.9451C26.6812 13.1641 24.0452 10 20 10C15.9548 10 13.3188 13.1641 13.3188 15.9451C13.3188 19.5054 11.5 19.3188 11.5 22.2205C11.753 25.1352 14.3618 25.8476 20 25.8476Z" stroke="#060628" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.3889 28.8572C21.0247 30.3719 18.8967 30.3899 17.5195 28.8572" stroke="#060628" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            {
                status &&
                <>
                    <circle cx="25" cy="13" r="4" fill="#00BB64"/>
                        <circle cx="25" cy="13" r="4" fill="black" fillOpacity="0.2"/>
                    <circle cx="25" cy="13" r="4" stroke="white" strokeWidth="2"/>
                </>
            }
        </svg>
    )
}

Notification.propTypes = {
    status: PropTypes.bool,
};