import React from "react";
import PropTypes from 'prop-types'

// Components
import LabelArrow from '../../assets/icons/LabelArrow'

export default function Label({titles, title}) {
    return(
        <div className="text-grey mb-[10px] text-12 relative">
            <div className="flex items-center overflow-x-auto scrollbar-hide">
                {
                    titles?.map((item) => <p key={item?.id} className='mr-[21px] w-max whitespace-nowrap'>{item?.name}</p>)
                }
                { title && <p className='mr-[21px] w-max whitespace-nowrap'>{title}</p> }
            </div>
            {
                titles?.length && <div className="absolute right-0 bottom-0 cursor-pointer"><LabelArrow /></div>
            }
        </div>
    )
}

Label.propTypes ={
    titles: PropTypes.array,
    title: PropTypes.string
}