import { utility, mobile } from '../../../services/endpoints'
import customAsyncThunk from '../../../utils/createThunk'

const getBillerByCategory = customAsyncThunk('utility/getBillerByCategory', 'get', utility.get_biller_by_category)
const getBillerPaymentItems = customAsyncThunk('utility/getBillerPaymentItems', 'get', utility.get_biller_payment_items)
const sendPaymentAdvice = customAsyncThunk('utility/sendPaymentAdvice', 'post', utility.send_payment_advice)
const purchaseUtility = customAsyncThunk('utility/purchaseUtility', 'post', utility.purchase)
const schedule = customAsyncThunk('utility/schedule', 'post', utility.schedule)
const getDataBundle = customAsyncThunk('utility/getDataBundle', 'get', mobile.get_data_bundle)
const editScheduleUtility = customAsyncThunk('utility/editScheduleUtility', 'patch', utility.schedule)
const payWithCard = customAsyncThunk('utility/payWithCard', 'post', utility.pay_with_card)
const payWithUSSD = customAsyncThunk('utility/payWithUSSD', 'post', utility.pay_with_ussd)
const getDiscount = customAsyncThunk('utility/getDiscount', 'get', utility.get_discount)
const validateCustomerDetails = customAsyncThunk('utility/validateCustomerDetails', 'post', utility.validate_customer_details)

export {
    getBillerByCategory,
    getBillerPaymentItems,
    sendPaymentAdvice,
    purchaseUtility,
    schedule,
    getDataBundle,
    editScheduleUtility,
    payWithCard,
    payWithUSSD,
    getDiscount,
    validateCustomerDetails
}