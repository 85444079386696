import { useAppDispatch, useAppSelector } from "../useAppDispatch"
import { unwrapResult } from "@reduxjs/toolkit"
import { notify } from "../../store/modules/global"
import { useState } from "react"
// Actions
import {
    login, createAccount, createTag,
    forgotPassword, resetPassword, verifyAccount,
    validatePasswordResetToken, resendVerificationEmail, requestPasswordChange,
    changePassword, confirmPassword, createPin,
    fetchSecurityQuestions, createSecurityQuestion, fetchUserSelectedQuestions,
    validateSecurityAnswer, resendOtp, validateOtp
} from "../../store/modules/auth/actions"
// utils
import { removeSpecificKeys } from "../../utils/removeEmptyKeys"
// Custom Hooks
import useValidateFormData from "../useValidateFormData"
import useHandleError from "../useHandleError"
import useMakeRequest from "../useMakeRequest"
import useExtractPIN from "../useExtractPIN"
import useInputValidate from "../useInputValidate"
import { useSelector } from "react-redux"
import CryptoJS from "crypto-js"
import encryptData from "utils/encryptData"
//Import Mixpanel SDK
import mixpanel from 'mixpanel-browser';




export default function useAuth() {
    let dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const { validateFormData } = useValidateFormData()
    const { checkPin } = useInputValidate()
    const { extractPIN } = useExtractPIN()
    const { makeDispatch } = useMakeRequest()
    const { handleError } = useHandleError()
    const [showModal, setShowModal] = useState(false);
    const { recoverUser, loggedUser } = useAppSelector((state) => state.auth)
    const { profile } = useSelector(state => state.user)
    // Near entry of your product, init Mixpanel
    mixpanel.init(process.env.REACT_APP_MIX_PANEL_KEY, {debug: false, track_pageview: false, persistence: 'localStorage'});

    // LOGIN USER
    const loginUser = async (formData, callback) => {
        const regexPhone = /^\d{11}$/

        try {
            const key = regexPhone.test(formData.email_or_phone) ? 'phone_number' : 'email'
            if (!validateFormData({ [key]: formData.email_or_phone }) || !formData?.password) return // check if formData is valid

            const enhancedFormData = {
                [key]: formData.email_or_phone,
                password: formData.password
            }
            setLoading(true)
            const value = encryptData(enhancedFormData)
            return dispatch(login({ payload: value }))
                .then(unwrapResult)
                .then((res) => {
                    if (res?.code === 200) {
                        mixpanel.identify(res?.data?.id)
                        mixpanel.people.set({ '$name': `${res?.data?.first_name} ${res?.data?.last_name}`,
                            '$email': res?.data?.email,
                            'Tier Levels' : res?.data?.tier_levels
                        });
                        mixpanel.track('User Logged In', {})
                        return res
                    }
                    if (res?.code === 400 && res?.message === 'User is not verified, please verify your email to proceed') {
                        const valueCode = encryptData({ email: formData?.email_or_phone })
                        makeDispatch({ action: resendVerificationEmail({ payload: valueCode }), callback, alert: false })
                        return
                    }
                    if (!res) {
                        dispatch(notify({ display: true, status: 'error', message: handleError({ message: 'Internal Server Error' }) }))
                        return
                    }
                    dispatch(notify({ display: true, status: 'error', message: handleError({ status: res?.code, message: res?.message }) }))
                })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // CREATE ACCOUNT
    const signup = async (formData, callback) => {
        try {
            if (!validateFormData(formData)) return // check if formData is valid
            let enhancedFormData = formData
            removeSpecificKeys(enhancedFormData, ["confirm_password"]) // filter undefined form values

            setLoading(true)
            const value = encryptData(enhancedFormData)
            dispatch(createAccount({ payload: value }))
                .then(unwrapResult)
                .then(res => {
                    if (res?.code === 201) {
                        //dispatch(notify({display: true, status: 'success', message: res?.message}))
                        mixpanel.identify(res?.data?.id)
                        mixpanel.people.set({ '$name': `${res?.data?.first_name} ${res?.data?.last_name}`,
                            '$email': res?.data?.email,
                            'Tier Levels' : res?.data?.tier_levels
                        });
                        mixpanel.track('User Signed Up', {})
                        callback()
                        return
                    }
                    if (res?.code === 400 && res?.message === 'User is not verified, please verify your email to proceed') {
                        dispatch(notify({ display: true, status: 'error', message: res?.message }))
                        const valueVerifyUser = encryptData({ email: formData?.email })
                        makeDispatch({ action: resendVerificationEmail({ payload: valueVerifyUser }), callback })
                        return
                    }
                    if (!res) {
                        dispatch(notify({ display: true, status: 'error', message: handleError({ message: 'Internal Server Error' }) }))
                        return
                    }
                    dispatch(notify({ display: true, status: 'error', message: handleError({ status: res?.code, message: res?.message }) }))
                })
                .catch(error =>
                    dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
                )
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // CREATE TAG
    const create = async (formData, callback) => {
        try {
            if (!validateFormData(formData)) return // check if formData is valid
            const value = encryptData(formData)
            makeDispatch({ action: createTag({ payload: value }), callback })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // CREATE PIN
    const create_pin = async (formData, callback) => {
        try {
            const confirm = extractPIN(formData.confirm)
            const pin = extractPIN(formData.pin)

            if (!checkPin(confirm) || !checkPin(pin)) return // check if formData is valid)

            if (pin !== confirm) {
                dispatch(notify({ display: true, status: 'error', message: 'Passwords do not match' }))
                return
            }
            setLoading(true)
            const value = encryptData({ pin })
            makeDispatch({ action: createPin({ payload: value }), callback })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // FORGOT PASSWORD
    const forgot_password = async (formData, callback) => {
        try {
            if (!validateFormData(formData)) return; // check if formData is valid
            const value = encryptData(formData)
            makeDispatch({ action: forgotPassword({ payload: value }), callback })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    };

    // RESET PASSWORD
    const reset_password = async (formData, callback) => {
        try {
            let enhancedFormData = formData;

            // filter undefined form values
            removeSpecificKeys(enhancedFormData, ["confirm_password"])

            if (!validateFormData(formData)) return; // check if formData is valid
            const data = { ...enhancedFormData, email: recoverUser?.email }
            const value = encryptData(data)
            makeDispatch({
                action: resetPassword({ payload: value }),
                callback,
                alert: false
            })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    };

    // VERIFY ACCOUNT
    const verify_account = async (pin, callback) => {
        try {
            const result = extractPIN(pin)
            const data = { otp: result }
            const value = encryptData(data)
            makeDispatch({ action: verifyAccount({ payload: value }), callback })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    };

    // VALIDATE OTP
    const validate_otp = async (pin, callback) => {
        try {
            const result = extractPIN(pin)
            const data = { otp: result }
            const value = encryptData(data)
            makeDispatch({ action: validateOtp({ payload: value }), callback })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    };

    // VALIDATE PASSWORD RESET TOKEN
    const validate_password_reset_Token = async (email, pin, callback) => {
        try {
            setLoading(true)
            const result = extractPIN(pin)
            const data = { email, reset_code: result }
            const value = encryptData(data)
            makeDispatch({
                action: validatePasswordResetToken({
                    payload: value
                }),
                callback,
                alert: false
            })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    };

    // RESEND VERIFICATION EMAIL
    const resend_verification_email = async (email, callback) => {
        const value = encryptData({ email })
        makeDispatch({ action: resendVerificationEmail({ payload: value }), callback })
    }

    // RESEND CODE
    const resend_otp = async (callback) => {
        const data = { email: loggedUser?.email || profile?.email }
        const value = encryptData(data)
        makeDispatch({ action: resendOtp({ payload: value }), callback })
    }

    // REQUEST PASSWORD CHANGE
    const request_password_change = async (email, callback) => {
        setLoading(true)
        const value = encryptData({ email })
        makeDispatch({ action: requestPasswordChange({ payload: value }), callback })
            .finally(() => setLoading(false))
    }

    // CHANGE PASSWORD
    const change_password = async (formData, callback) => {
        try {
            if (!validateFormData(formData)) return // check if formData is valid
            const data = { ...formData, email: profile?.email }
            const value = encryptData(data)
            makeDispatch({
                action: changePassword({ payload: value }),
                callback,
                alert: false
            })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // CONFIRM PASSWORD
    const confirm_password = async (formData, callback) => {
        try {
            setLoading(true)
            const data = { ...formData, email: profile?.email }
            const value = encryptData(data)
            makeDispatch({
                action: confirmPassword({ payload: value }),
                callback,
                alert: false
            })
            .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // FETCH ALL SECURITY QUESTIONS
    const fetch_security_questions = async () => {
        try {
            makeDispatch({ action: fetchSecurityQuestions(), alert: false })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // FETCH USER SELECTED SECURITY QUESTIONS
    const fetch_user_selected_questions = async () => {
        try {
            setLoading(true)
            makeDispatch({ action: fetchUserSelectedQuestions(), alert: false })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // CREATE SECURITY QUESTION
    const create_security_question = async (formData, callback) => {
        try {
            if (!validateFormData(formData)) return // check if formData is valid
            makeDispatch({ action: createSecurityQuestion({ payload: { ...formData } }), callback, alert: false })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // VALIDATE SECURITY QUESTION
    const validate_security_answer = async (formData, callback) => {
        try {
            if (!validateFormData(formData)) return // check if formData is valid
            setLoading(true)
            makeDispatch({ action: validateSecurityAnswer({ payload: { ...formData } }), callback, alert: false })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    return {
        loading,
        setLoading,
        loginUser,
        signup,
        create,
        forgot_password,
        reset_password,
        showModal,
        setShowModal,
        verify_account,
        validate_otp,
        resend_verification_email,
        request_password_change,
        change_password,
        confirm_password,
        create_pin,
        makeDispatch,
        fetch_security_questions,
        create_security_question,
        fetch_user_selected_questions,
        validate_security_answer,
        validate_password_reset_Token,
        resend_otp
    }
}