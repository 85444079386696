import { logout, refreshToken } from "../store/modules/auth/actions"
import { notify } from "../store/modules/global"
import { unwrapResult } from "@reduxjs/toolkit"

let store
export const injectStore = _store => store = _store
export const getUser = () => store?.getState()?.auth

const getRefreshToken = () => {
	const { auth } = JSON.parse(localStorage.getItem('persist:root'))
	const loggedUser = JSON.parse(auth).loggedUser
    const refreshToken = loggedUser?.refreshToken
	return refreshToken
}

export const updateUser = () => {
    const refresh_token = getRefreshToken() || getUser()?.loggedUser?.refreshToken
    return store.dispatch(refreshToken({ payload: { refresh_token } }))
        .then(unwrapResult)
        .then(res => {
            if (res?.code !== 200) {
                store.dispatch(notify({ display: true, status: 'error', message: res?.message }))
                store.dispatch(logout())
            }
            return res
        })
        .catch(error => error)
}