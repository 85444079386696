import React from "react";
import PropTypes from "prop-types"

export default function Account({ color }) {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99766 12.6455C6.4035 12.6455 3.3335 13.2122 3.3335 15.4788C3.3335 17.7463 6.38433 18.333 9.99766 18.333C13.5918 18.333 16.6618 17.7672 16.6618 15.4997C16.6618 13.2322 13.6118 12.6455 9.99766 12.6455Z" fill={color || "#060628"}/>
            <path opacity="0.4" d="M9.99765 10.4865C12.446 10.4865 14.4076 8.524 14.4076 6.0765C14.4076 3.629 12.446 1.6665 9.99765 1.6665C7.55015 1.6665 5.58765 3.629 5.58765 6.0765C5.58765 8.524 7.55015 10.4865 9.99765 10.4865Z" fill={color || "#060628"}/>
        </svg>
    )
}

Account.propTypes = {
    color: PropTypes.string
};