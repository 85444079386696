import { useState } from "react"
import { useAppDispatch } from "../useAppDispatch"
import { notify } from "../../store/modules/global"
// actions
import { getUserByTag } from "../../store/modules/user/actions"
import {
    editScheduleLocalTransfer,
    editScheduleP2PTransfer, getEnv,
    getWalletBalance, localBankTransfer, scheduleLocalTransfer,
    scheduleP2PTransfer, transferMoneyP2P, deleteSchedule, getRequests, updateRequest,
    addBeneficiary,
    deleteBeneficiary,
    getAllBeneficiaries
} from "../../store/modules/payment/actions"
import { editScheduleUtility } from "../../store/modules/utility/actions"
// hooks
import useMakeRequest from "../../hooks/useMakeRequest"
import useHandleError from "../useHandleError"
// utils
import { removeSpecificKeys } from "../../utils/removeEmptyKeys"
import encryptData from "utils/encryptData"
import { MIXPANEL_LOCALBANKTRANSFER, MIXPANEL_TRANSFERMONEYP2P } from "constants/enum"

export default function usePayment() {
    let dispatch = useAppDispatch()
    const { makeDispatch } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const [receipt, setReceipt] = useState({})
    const [beneficiary, setBeneficiary] = useState()
    const { handleError } = useHandleError()

    const getBeneficiary = async (tag) => {
        try {
            setLoading(true)
            setBeneficiary()
            makeDispatch({ action: getUserByTag({ params: tag }), alert: false })
                .then((res) => setBeneficiary(res?.data ? res?.data : {}))
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
        }
    }

    const transfer = (formData, method, callback) => {
        try {
            setLoading(true)
            let enhancedFormData = { ...formData, amount: formData?.amount * 1 } // convert amount to number
            removeSpecificKeys(enhancedFormData) // filter undefined form values
            
            const value = encryptData(enhancedFormData)

            return makeDispatch({
                type: method === 'pouchers' ? MIXPANEL_TRANSFERMONEYP2P : MIXPANEL_LOCALBANKTRANSFER,
                action: method === 'pouchers' ?
                    transferMoneyP2P({ payload: value }) :
                    localBankTransfer({ payload: value }),
                alert: false
            })
                .then((res) => {
                    if (res?.code === 200) {
                        setReceipt(res?.data ? res?.data : {})
                        callback()
                    }
                })
                .finally(() => {
                    setLoading(false)
                    makeDispatch({ action: getWalletBalance(), alert: false })
                })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    const scheduleTransfer = (formData, method, callback) => {
        try {
            setLoading(true)
            let enhancedFormData = {
                ...formData, amount: formData.amount * 1, sub_category: "none",
                category: method === 'pouchers' ? "p2p-transfer" : "local-bank-transfer"
            }
            removeSpecificKeys(enhancedFormData) // filter undefined form values

            const value = encryptData(enhancedFormData)

            return makeDispatch({
                action: method === 'pouchers' ? scheduleP2PTransfer({ payload: value }) : scheduleLocalTransfer({ payload: value }),
                alert: true
            })
                .then((res) => {
                    if ([200, 201].includes(res?.code)) callback()
                })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    const editScheduleTransfer = (formData, category, callback) => {
        try {
            const { frequency, transactionPin, status } = formData
            const enhancedFormData = { frequency, transactionPin, status }
            setLoading(true)
            const value = encryptData(enhancedFormData)
            return makeDispatch({
                action: category.match(/p2p-transfer/i) ?
                    editScheduleP2PTransfer({ payload: value, params: `${formData?.schedule_id}` }) :
                    editScheduleLocalTransfer({
                        payload: value, params: `${formData?.schedule_id}`
                    }),
                alert: true
            })
                .then((res) => {
                    if ([200, 201].includes(res?.code)) callback()
                    return res
                })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    const edit_schedule_utility = (formData, callback) => {
        try {
            const { frequency, transactionPin, status } = formData
            const enhancedFormData = { frequency, transactionPin, status }

            const value = encryptData(enhancedFormData)

            setLoading(true)
            return makeDispatch({
                action: editScheduleUtility({ payload: value, params: `${formData?.schedule_id}` }),
                alert: true
            })
                .then((res) => {
                    if ([200, 201].includes(res?.code)) callback()
                    return res
                })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    const delete_schedule = (scheduleId, callback) => {
        try {
            setLoading(true)
            return makeDispatch({
                action: deleteSchedule({ params: scheduleId }),
                alert: true
            })
                .then((res) => {
                    if ([200, 201].includes(res?.code)) callback()
                    return res
                })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    const get_env = async (type) => {
        try {
            setLoading(true)
            return makeDispatch({ action: getEnv({ params: type }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
        }
    }

    const get_requests = async ({ params }) => {
        try {
            setLoading(true)
            return makeDispatch({ action: getRequests({ params }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
        }
    }

    const update_request = async ({ payload  }) => {
        try {
            setLoading(true)
            const value = encryptData(payload)
            return makeDispatch({ action: updateRequest({ payload: value }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
        }
    }

    const update_beneficiary_list = (formData) => {
        try {
            setLoading(true)
            const { create, ...data } = formData 
            const value = encryptData(data)

            return makeDispatch({
                action: create ? addBeneficiary({ payload: value }) : deleteBeneficiary({ params: data?.beneficiary_id }),
                alert: true
            })
                .then((res) => res)
                .finally(() => {
                    setLoading(false)
                    makeDispatch({ action: getAllBeneficiaries(), alert: false })
                })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }


    return {
        loading,
        setLoading,
        beneficiary,
        receipt,
        setBeneficiary,
        getBeneficiary,
        transfer,
        scheduleTransfer,
        editScheduleTransfer,
        edit_schedule_utility,
        delete_schedule,
        get_env,
        get_requests,
        update_request,
        update_beneficiary_list
    }
}