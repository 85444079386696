import React from "react"
import PropTypes from "prop-types"

export default function FormWrapper({ onSubmit, children, className, autoComplete, title, description }) {
    const submitForm = (e) => {
        e.preventDefault()
        onSubmit && onSubmit()
    }

    return (
        <form
            autoComplete={autoComplete}
            data-testid="form"
            className={className}
            onSubmit={submitForm}
        >
            {title && <h1 className="font-dmsans_m text-24 capitalize">{title}</h1>}
            {description && <p className="text-16 font-dmsans_r font-normal leading-24 text-grey mt-[4px]">{description}</p>}
            <div className="mt-[20px]">{children}</div>
        </form>
    )
}

FormWrapper.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
    autoComplete: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
}