import { useState } from "react"
import { useAppDispatch } from "../useAppDispatch"
import { notify } from "../../store/modules/global"
// actions
import {
    freezeVirtualCard, getVirtualCardBalance, getVirtualCardDetails, getVirtualCardToken, getVirtualCards
} from "store/modules/card/actions"
// hooks
import useMakeRequest from "../../hooks/useMakeRequest"
import useHandleError from "../useHandleError"
import encryptData from "utils/encryptData"

export default function useCard() {
    let dispatch = useAppDispatch()
    const [virtualCardBalance, setVirtualCardBalance] = useState()
    const { makeDispatch } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { handleError } = useHandleError()

    // get single card detail
    const getCardDetails = async (id) => {
        try {
            setLoading(true)
            return makeDispatch({ action: getVirtualCardDetails({ params: `${id}/details` }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
        }
    }

    // get single card balance
    const getCardBalance = async (id) => {
        try {
            setLoading(true)
            return makeDispatch({ action: getVirtualCardBalance({ params: `${id}/balance` }), alert: false })
                .then((res) => setVirtualCardBalance(res?.data ? res?.data : undefined))
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
        }
    }

    // get single card token
    const getCardToken = async (id) => {
        try {
            setLoading(true)
            return makeDispatch({ action: getVirtualCardToken({ params: `${id}/token` }), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
        }
    }

    // freeze card
    const freezeCard = async (id, formData, callback) => {
        try {
            setLoading(true)
            const value = encryptData(formData)
            return makeDispatch({ action: freezeVirtualCard({ params: id, payload: value }), alert: false })
                .then((res) => {
                    if ([200, 201].includes(res?.code)) callback()
                })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({ message: error.message }) }))
        }
    }

    const get_virtual_cards = async (id) => {
        setLoading(true)
        return makeDispatch({ action: getVirtualCards({ params: `${id}/all` }), alert: false })
            .finally(() => setLoading(false))
    }

    return {
        loading,
        setLoading,
        getCardDetails,
        getCardBalance,
        getCardToken,
        get_virtual_cards,
        freezeCard,
        virtualCardBalance
    }
}