import { createAsyncThunk } from '@reduxjs/toolkit'
import instance from 'services/axios-instance'
import { user } from '../../../services/endpoints'
import customAsyncThunk from '../../../utils/createThunk'

const changeTransactionPIN = customAsyncThunk('user/changeTransactionPIN', 'patch', user.change_transaction_pin)
const disableUser = customAsyncThunk('user/disableUser', 'patch', user.disable_user)
const deleteUser = customAsyncThunk('user/deleteUser', 'delete', user.delete_user)
const validateBvn = customAsyncThunk('user/validateBvn', 'patch', user.validate_bvn)
const editProfile = customAsyncThunk('user/editProfile', 'patch', user.profile)
const validateID = customAsyncThunk('user/validateID', 'patch', user.validate_id)
const requestPhoneChange = customAsyncThunk('user/requestPhoneChange', 'patch', user.request_phone_change)
const changePhoneNumber = customAsyncThunk('user/changePhoneNumber', 'patch', user.change_phone_number)
const getTiers = customAsyncThunk('user/getTiers', 'get', user.tiers)
const validate2faToken = customAsyncThunk('user/validate2faToken', 'post', user.validate_2fa_token)
const generate2faToken = customAsyncThunk('user/generate2faToken', 'post', user.generate_2fa_token)
const fetchProfile = customAsyncThunk('user/fetchProfile', 'get', user.profile)
const getUserByTag = customAsyncThunk('user/getUserByTag', 'get', user.profile)
const disable2fa = customAsyncThunk('user/disable2fa', 'patch', user.disable_2fa)
const requestUploadUrl = customAsyncThunk('user/requestUploadUrl', 'patch', user.request_upload_url)
const getTransactionHistory = customAsyncThunk('user/getTransactionHistory', 'get', user.transaction_history)
const getTransactionAnalytics = customAsyncThunk('user/getTransactionAnalytics', 'get', user.transaction_analytics)
const getReferrals = customAsyncThunk('user/getReferrals', 'get', user.get_referrals)
const getSchedules = customAsyncThunk('user/getSchedules', 'get', user.get_schedules)
const getBanners = customAsyncThunk('user/getBanners', 'get', user.get_banners)
const getNotifications = customAsyncThunk('user/getNotifications', 'get', user.get_notifications)
const getAllNotifications = customAsyncThunk('user/getAllNotifications', 'get', user.get_all_notifications)
const readNotification = customAsyncThunk('user/readNotification', 'patch', user.read_notification)
const readAllNotifications = customAsyncThunk('user/readAllNotifications', 'patch', user.read_all_notifications)
const readAllPaymentRequest = customAsyncThunk('user/readAllPaymentRequest', 'patch', user.read_all_payment_request)
const getPaymentRequestCount = customAsyncThunk('user/getPaymentRequestCount', 'get', user.get_payment_request_count)
const uploadImage = createAsyncThunk('user/uploadImage', async (payload) => {
    try {
        const response = await instance.put(payload.url, payload.file, {
            headers: { 'Content-Type': 'image/*' }
        })
        return response.data ? response.data : {}
    } catch (error) {
        return Promise.reject(error)
    }
})

export {
    changeTransactionPIN,
    disableUser,
    deleteUser,
    validateBvn,
    validateID,
    fetchProfile,
    requestPhoneChange,
    changePhoneNumber,
    editProfile,
    getTiers,
    generate2faToken,
    validate2faToken,
    disable2fa,
    requestUploadUrl,
    uploadImage,
    getUserByTag,
    getTransactionHistory,
    getTransactionAnalytics,
    getReferrals,
    getSchedules,
    getBanners,
    getNotifications,
    getAllNotifications,
    readNotification,
    readAllNotifications,
    getPaymentRequestCount,
    readAllPaymentRequest
}