import React from "react";

// Icons
import Analysis from "../assets/icons/Analysis";
import Card from "../assets/icons/Card";
import Home from "../assets/icons/Home";
import Transactions from "../assets/icons/Transactions";
import Account from "../assets/icons/Account";

export const nav_links = [
    {
        title: 'Home',
        id: 0,
        icon: <Home color="#5034C4" />,
        url: '/'
    },
    {
        title: 'Transactions',
        id: 1,
        icon: <Transactions color="#5034C4" />,
        url: '/transactions'
    },
    {
        title: 'Card',
        id: 2,
        icon: <Card color="#5034C4" />,
        url: '/card'
    },
    {
        title: 'Analytics',
        id: 3,
        icon: <Analysis color="#5034C4" />,
        url: '/analytics'
    },
]

export const mobile_nav_links = [
    {
        title: 'Home',
        id: 0,
        icon: <Home color="#5034C4" />,
        url: '/'
    },
    {
        title: 'Transactions',
        id: 1,
        icon: <Transactions color="#5034C4" />,
        url: '/transactions'
    },
    {
        title: 'Card',
        id: 2,
        icon: <Card color="#5034C4" />,
        url: '/card'
    },
    {
        title: 'Account',
        id: 3,
        icon: <Account color="#5034C4" />,
        url: '/account'
    },
]