import React from 'react';
import PropTypes from "prop-types"
import Lottie from 'react-lottie-player'
// Lottie
import successLottie from '../assets/Lottie/success';
// Icons
import Recycle from "../assets/icons/Recycle"
import Upload from "../assets/icons/Upload"
// Components
import Button from './Button/Button';

export default function Success({ title, description, buttonName, onClick, schedule, share }) {
    return (
        <div className='flex flex-col items-center success'>
            <>
                <div className='w-full flex justify-center items-center h-[200px]'>
                    <Lottie
                        loop={false}
                        animationData={successLottie}
                        play
                        style={{ width: 150, height: 150 }}
                    />
                </div>
                <p className="text-24 text-dark_blue font-dmsans_m font-medium text-center">{title}</p>
                <p className="text-grey mt-2 text-sm font-dmsans_r font-normal text-center mb-[24px]">{description}</p>
                <div className='flex w-full'>
                    {
                        schedule &&
                        <Button
                            onClick={schedule}
                            theme="smoke"
                            styles="mt-6 mr-[13px] sm:mr-[23px] w-full"
                        >
                            <><Recycle /><span className='ml-[10px] text-14 sm:text-15'>Schedule</span></>
                        </Button>
                    }
                    {
                        share &&
                        <Button
                            onClick={share}
                            theme="smoke"
                            styles="mt-6 w-full"
                        >
                            <><Upload /><span className='ml-[10px] text-14 sm:text-15'>Share receipt</span></>
                        </Button>
                    }
                </div>
                {
                    buttonName &&
                    <Button
                        name={buttonName}
                        onClick={onClick}
                        theme="dark"
                        styles="mt-6 w-full"
                    />
                }
            </>
        </div>
    )
}

Success.propTypes = {
    schedule: PropTypes.func,
    share: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonName: PropTypes.string,
    onClick: PropTypes.func
}