import React from "react"
import PropTypes from 'prop-types';
import useInputValidate from "../../hooks/useInputValidate";

export default function Pin({ id, name, onChange, onKeyDown, onPaste, inputRef, readOnly, type, pin, className }) {
    const { error } = useInputValidate()

    return (
        <div className={className}>
            {pin?.map((currentInput, index) =>
                <div key={currentInput.key} className={`w-full sm:w-[57px] gap-[10px]`}>
                    <input
                        data-testid={`${id}-${index}`}
                        id={`${id}-${index}`}
                        name={`${name}-${index}`}
                        ref={e => inputRef.current[currentInput.key] = e}
                        onChange={(e) => onChange(e, currentInput)}
                        onKeyDown={(e) => onKeyDown(e, currentInput)}
                        aria-labelledby={id}
                        onPaste={(e) => onPaste(e)}
                        readOnly={readOnly}
                        autoComplete="off"
                        maxLength={1}
                        inputMode="numeric"
                        type={type || "password"}
                        className={
                            `${error === '' ? 'border-purple_transparent' : 'border-red'}
                                p-16 text-16 text-center w-full outline-0 border font-dmsans_r font-normal 
                                rounded-lg focus:border-purple focus:border bg-green_light`
                        }
                        //readOnly={loading}
                        value={currentInput.value}
                    />
                </div>
            )}
            <p className="font-dmsans_r text-red text-12 leading-[16px]">{error}</p>
        </div>
    )
}

Pin.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onPaste: PropTypes.func,
    placeholder: PropTypes.string,
    inputRef: PropTypes.object,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    styles: PropTypes.string,
    style: PropTypes.string,
    maxLength: PropTypes.number,
    pin: PropTypes.array,
    className: PropTypes.string
};