import {
  validateBvn,
  fetchProfile,
  validateID,
  changePhoneNumber,
  editProfile,
  getTiers,
  generate2faToken,
  validate2faToken,
  disable2fa,
  getBanners,
  getNotifications,
  getReferrals,
} from './actions';

const setProfile = (state, action) => {
  if(action?.payload?.data) state.profile = action?.payload?.data
}

const extraReducers = {
  // Validate bvn
  [validateBvn.fulfilled]: (state, action) => setProfile(state, action),
  // Fetch User Profile
  [fetchProfile.fulfilled]: (state, action) => setProfile(state, action),
  // Validate ID
  [validateID.fulfilled]: (state, action) => setProfile(state, action),
  // Change Phone Number
  [changePhoneNumber.fulfilled]: (state, action) => setProfile(state, action),
  // Edit Profile
  [editProfile.fulfilled]: (state, action) => setProfile(state, action),
  // Validate 2fa
  [validate2faToken.fulfilled]: (state, action) => setProfile(state, action),
  // Disable 2fa
  [disable2fa.fulfilled]: (state, action) => setProfile(state, action),
  // Get Tiers
  [getTiers.fulfilled]: (state, action) => {
    if(action?.payload?.data) state.tiers = action?.payload?.data
  },
  // Generate 2fa
  [generate2faToken.fulfilled]: (state, action) => {
    if(action?.payload?.data) state.twoFactorAuthentication = action?.payload?.data
  },
  // Get Banners
  [getBanners.fulfilled]: (state, action) => {
    if(action?.payload?.data) state.banners = action?.payload?.data
  },
  // Get Notifications
  [getNotifications.fulfilled]: (state, action) => {
    if(action?.payload?.data) state.notifications = action?.payload?.data
  },
  // Get Referrals
  [getReferrals.fulfilled]: (state, action) => {
    if(action?.payload?.data) state.referrals = action?.payload?.data
  },
};

export { extraReducers };
