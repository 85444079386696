import { payment } from '../../../services/endpoints'
import customAsyncThunk from '../../../utils/createThunk'

const requestMoney = customAsyncThunk('payment/requestMoney', 'post', payment.request_money)
const transferMoneyP2P = customAsyncThunk('payment/transferMoneyP2P', 'post', payment.transfer_money_p2p)
const getLocalBankAccountDetails = customAsyncThunk('payment/getLocalBankAccountDetails', 'get', payment.get_local_bank_account_details)
const localBankTransfer = customAsyncThunk('payment/localBankTransfer', 'post', payment.transfer_money_local_bank)
const getAllBanks = customAsyncThunk('payment/getAllBanks', 'get', payment.get_all_banks)
const getAllGuestUserBanks = customAsyncThunk('payment/getAllGuestUserBanks', 'get', payment.get_all_guest_user_banks)
const getWalletBalance = customAsyncThunk('payment/getWalletBalance', 'get', payment.get_wallet_balance)
const scheduleP2PTransfer = customAsyncThunk('payment/scheduleP2PTransfer', 'post', payment.schedule_p2p_transfer)
const scheduleLocalTransfer = customAsyncThunk('payment/scheduleLocalTransfer', 'post', payment.schedule_local_transfer)
const editScheduleP2PTransfer = customAsyncThunk('payment/editScheduleP2PTransfer', 'patch', payment.schedule_p2p_transfer)
const editScheduleLocalTransfer = customAsyncThunk('payment/editScheduleLocalTransfer', 'patch', payment.schedule_local_transfer)
const deleteSchedule = customAsyncThunk('payment/deleteSchedule', 'delete', payment.delete_schedule)
const getEnv = customAsyncThunk('payment/getEnv', 'get', payment.env)
const checkPaymentStatus = customAsyncThunk('payment/checkPaymentStatus', 'post', payment.check_payment_status)
const getRequests = customAsyncThunk('payment/getRequests', 'get', payment.requests)
const updateRequest = customAsyncThunk('payment/updateRequest', 'patch', payment.request)
const getAllBeneficiaries = customAsyncThunk('payment/getAllBeneficiaries', 'get', payment.get_all_beneficiaries)
const addBeneficiary = customAsyncThunk('payment/addBeneficiary', 'post', payment.add_beneficiary)
const deleteBeneficiary = customAsyncThunk('payment/deleteBeneficiary', 'delete', payment.delete_beneficiary)



export {
    requestMoney,
    transferMoneyP2P,
    getLocalBankAccountDetails,
    localBankTransfer,
    getAllBanks,
    getAllGuestUserBanks,
    getWalletBalance,
    scheduleP2PTransfer,
    scheduleLocalTransfer,
    editScheduleP2PTransfer,
    editScheduleLocalTransfer,
    checkPaymentStatus,
    deleteSchedule,
    getEnv,
    getRequests,
    updateRequest,
    getAllBeneficiaries,
    addBeneficiary,
    deleteBeneficiary
}