import React from "react";
import PropTypes from "prop-types"

export default function Transactions({ color }) {
    return(
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="1">
            <path opacity="1" d="M6.48273 11.8164C6.00963 11.8164 5.62664 12.2051 5.62664 12.6852L5.39062 16.8824C5.39062 17.4943 5.87982 17.9897 6.48273 17.9897C7.08565 17.9897 7.57377 17.4943 7.57377 16.8824L7.33883 12.6852C7.33883 12.2051 6.95584 11.8164 6.48273 11.8164Z" fill={color || "#060628"}/>
            <path d="M7.65095 3.36733C7.65095 3.36733 7.40528 3.11473 7.25294 3.00477C7.03194 2.83492 6.75945 2.75 6.48804 2.75C6.18336 2.75 5.90014 2.84581 5.66949 3.02654C5.62765 3.06901 5.44957 3.22905 5.30259 3.37821C4.37998 4.25031 2.87056 6.52691 2.40925 7.7191C2.3363 7.89983 2.1786 8.35711 2.16895 8.60208C2.16895 8.83508 2.22044 9.05936 2.32557 9.27167C2.47255 9.53733 2.7032 9.75072 2.97569 9.86722C3.1645 9.94235 3.72987 10.0588 3.7406 10.0588C4.3596 10.1764 5.36589 10.2396 6.47731 10.2396C7.53616 10.2396 8.50061 10.1764 9.12927 10.0806C9.14 10.0697 9.84161 9.95323 10.083 9.82476C10.5228 9.59068 10.7964 9.1334 10.7964 8.64454V8.60208C10.7857 8.28308 10.5121 7.6124 10.5024 7.6124C10.0411 6.48445 8.60467 4.2612 7.65095 3.36733Z" fill={color || "#060628"}/>
            <path opacity="1" d="M16.1888 10.1839C16.6619 10.1839 17.0449 9.7952 17.0449 9.31505L17.2798 5.11779C17.2798 4.50589 16.7917 4.0105 16.1888 4.0105C15.5859 4.0105 15.0967 4.50589 15.0967 5.11779L15.3327 9.31505C15.3327 9.7952 15.7157 10.1839 16.1888 10.1839Z" fill={color || "#060628"}/>
            <path d="M20.3458 12.7282C20.1988 12.4625 19.9682 12.2502 19.6957 12.1326C19.5069 12.0575 18.9405 11.941 18.9308 11.941C18.3118 11.8234 17.3055 11.7603 16.1941 11.7603C15.1352 11.7603 14.1708 11.8234 13.5421 11.9192C13.5314 11.9301 12.8298 12.0477 12.5884 12.1751C12.1475 12.4092 11.875 12.8665 11.875 13.3564V13.3989C11.8857 13.7179 12.1582 14.3875 12.1689 14.3875C12.6303 15.5155 14.0657 17.7398 15.0204 18.6327C15.0204 18.6327 15.2661 18.8852 15.4185 18.9941C15.6384 19.1651 15.9109 19.25 16.1844 19.25C16.488 19.25 16.7702 19.1542 17.0019 18.9734C17.0437 18.931 17.2218 18.7709 17.3688 18.6229C18.2903 17.7496 19.8008 15.473 20.2611 14.2819C20.3351 14.1011 20.4928 13.6428 20.5025 13.3989C20.5025 13.1648 20.451 12.9405 20.3458 12.7282Z" fill={color || "#060628"}/>
            </g>
        </svg>
    )
}

Transactions.propTypes = {
    color: PropTypes.string
};