import instance from 'services/axios-instance'
import { createAsyncThunk } from '@reduxjs/toolkit'

const customAsyncThunk = (thunkName, method, url) =>
    createAsyncThunk(thunkName, async (payload) => {
    try {
        const body = { ...payload?.payload }
        const params = payload?.params ? payload?.params : ''
        const response = await instance[method || 'get'](`${url}/${params}`, body)
        if (response?.data) return response?.data ? response?.data : {}
        if (response?.response?.data) return response?.response?.data ? response?.response?.data : {}
    } catch (error) {
        return error
    }
})

export default customAsyncThunk;
