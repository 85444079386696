import React from "react"
import PropTypes from "prop-types"

export default function Avatar({url, alt, initials, width, height, className}) {
    return(
        <div className={`bg-purple w-[${width}] h-[${height}] rounded-full flex justify-center items-center text-white ${className}`}>
            {
                url ?
                <img 
                    src={url} 
                    width="40px" 
                    height="40px" 
                    alt={alt}
                    className={`rounded-full w-[${width}] h-[${height}] bg-green_light object-cover object-top`}
                /> :
                <p className="uppercase font-dmsans_b">{initials}</p>
            }
        </div>
    )
}

Avatar.propTypes = {
    url: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    initials: PropTypes.string
}