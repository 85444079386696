//Import Mixpanel SDK
import mixpanel from 'mixpanel-browser';
import {
    MIXPANEL_BOUGHTINTERNET, MIXPANEL_BOUGHTVOUCHER, MIXPANEL_ENTERPROFILESCREEN, MIXPANEL_FREEZEVIRTUALCARD, MIXPANEL_FUNDVIRTUALCARD,
    MIXPANEL_LOCALBANKTRANSFER, MIXPANEL_REDEEMEDVOUCHER, MIXPANEL_SCHEDULEDDATAPURCHASE, MIXPANEL_SCHEDULEDELECTRICITY,
    MIXPANEL_SCHEDULEDINTERNETSUBSCRIPTION, MIXPANEL_SENTVOUCHER, MIXPANEL_TRANSFERMONEYP2P, MIXPANEL_USERBOUGHTAIRTIME,
    MIXPANEL_USERBOUGHTBETTING, MIXPANEL_USERBOUGHTDATA, MIXPANEL_USERBOUGHTEDUCATION, MIXPANEL_USERBOUGHTELECTRICITY,
    MIXPANEL_USERCLICKSCOMPLETEDPAYMENTBUTTON, MIXPANEL_USERREFILLEDCABLETVSUBSCRIPTION, MIXPANEL_USERREQUESTEDMONEY,
    MIXPANEL_USERSCHEDULEDAIRTIMEPURCHASE, MIXPANEL_USERSCHEDULEDCABLETVPURCHASE, MIXPANEL_USERSUBMITTEDKYC
} from './enum';


mixpanel.init(process.env.REACT_APP_MIX_PANEL_KEY, {debug: false, track_pageview: false, persistence: 'localStorage'});


export const handleMixPanel = (type) => {
    switch (type) {
        case MIXPANEL_USERCLICKSCOMPLETEDPAYMENTBUTTON:
            return mixpanel.track('User clicks “I have completed payment” button', {})
        case MIXPANEL_TRANSFERMONEYP2P:
            return mixpanel.track('User transferred through Poucher Tag Button', {})
        case MIXPANEL_LOCALBANKTRANSFER:
            return mixpanel.track('User transferred through Bank Account', {})
        case MIXPANEL_USERREQUESTEDMONEY:
            return mixpanel.track('User Requested Money', {})
        case MIXPANEL_USERBOUGHTAIRTIME:
            return mixpanel.track('User Bought Airtime', {})
        case MIXPANEL_USERSCHEDULEDAIRTIMEPURCHASE:
            return mixpanel.track('User Scheduled Airtime Purchase', {})
        case MIXPANEL_USERBOUGHTDATA:
            return mixpanel.track('User Bought Data', {})
        case MIXPANEL_SCHEDULEDDATAPURCHASE:
            return mixpanel.track('User Scheduled Data Purchase', {})
        case MIXPANEL_USERBOUGHTELECTRICITY:
            return mixpanel.track('User Bought Electricity', {})
        case MIXPANEL_SCHEDULEDELECTRICITY:
            return mixpanel.track('User Scheduled Electricity', {})
        case MIXPANEL_BOUGHTINTERNET:
            return mixpanel.track('User Bought Internet', {})
        case MIXPANEL_SCHEDULEDINTERNETSUBSCRIPTION:
            return mixpanel.track('User Scheduled Internet Subscription', {})
        case MIXPANEL_USERREFILLEDCABLETVSUBSCRIPTION:
            return mixpanel.track('User Refilled CableTV Subscription', {})
        case MIXPANEL_USERSCHEDULEDCABLETVPURCHASE:
            return mixpanel.track('User Scheduled Cable TV Purchase', {})
        case MIXPANEL_USERBOUGHTEDUCATION:
            return mixpanel.track('User Bought Education', {})
        case MIXPANEL_USERBOUGHTBETTING:
            return mixpanel.track('User Bought Betting', {})
        case MIXPANEL_BOUGHTVOUCHER:
            return mixpanel.track('User Bought Voucher', {})
        case MIXPANEL_SENTVOUCHER:
            return mixpanel.track('User Sent Voucher', {})
        case MIXPANEL_REDEEMEDVOUCHER:
            return mixpanel.track('User Redeemed Voucher', {})
        case MIXPANEL_FUNDVIRTUALCARD:
            return mixpanel.track('User Fund Card', {})
        case MIXPANEL_FREEZEVIRTUALCARD:
            return mixpanel.track('User Delete Card', {})
        case MIXPANEL_USERSUBMITTEDKYC:
            mixpanel.track('User Submitted KYC', {})
            mixpanel.track('User Upgraded Profile Tier', {})
            break;
        case MIXPANEL_ENTERPROFILESCREEN:
            return mixpanel.track('Enter Profile Screen', {})
        default:
            break
    }
}