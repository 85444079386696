import { createSlice } from '@reduxjs/toolkit'
import { extraReducers } from './reducers'

const initialState = {
  loggedUser: {},
  signedUpUser: {},
  recoverUser: {},
  isLoggedIn: false,
  isSignedUp: false,
  loginSuccess: null,
  signupSuccess: null,
  loading: false,
  error: null,
  token: '',
  twoFactorCode: '',
  securityQuestions: [],
  userSelectedQuestions: []
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    disableTwoFactor: (state) => {
      state.two_factor = 'disabled'
    },
    setTwoFactorCode: (state, action) => {
      state.twoFactorCode = action?.payload
    }
  },
  extraReducers
})

// Action creators are generated for each case reducer function
export const { disableTwoFactor, setTwoFactorCode } = auth.actions

export default auth.reducer