import {
    getAllBanks, getEnv, getWalletBalance, getAllGuestUserBanks, getAllBeneficiaries,
} from './actions';

const extraReducers = {
    // get all banks
    [getAllBanks.fulfilled]: (state, action) => {
        if(action?.payload?.data) state.banks = action?.payload?.data?.data
    },
    // get all guest user banks
    [getAllGuestUserBanks.fulfilled]: (state, action) => {
        if(action?.payload?.data) state.guestUserBanks = action?.payload?.data
    },
    // get wallet balance
    [getWalletBalance.fulfilled]: (state, action) => {
        if(action?.payload?.data) state.wallet = action?.payload?.data
    },
    // get envs
    [getEnv.fulfilled]: (state, action) => {
        if(action?.payload?.data) state.envs = action?.payload?.data
    },
    // get beneficiaries
    [getAllBeneficiaries.fulfilled]: (state, action) => {
        if(action?.payload?.data) state.beneficiaries = action?.payload?.data
    },
};

export { extraReducers };
  