import { voucher } from '../../../services/endpoints'
import customAsyncThunk from '../../../utils/createThunk'

const buyVoucher = customAsyncThunk('voucher/buyVoucher', 'post', voucher.buy_voucher)
const redeemVoucher = customAsyncThunk('voucher/redeemVoucher', 'patch', voucher.redeem_voucher)
const giftVoucher = customAsyncThunk('voucher/giftVoucher', 'patch', voucher.gift_voucher)
const getVouchers = customAsyncThunk('voucher/getVouchers', 'get', voucher.get_vouchers)

export {
    buyVoucher,
    redeemVoucher,
    giftVoucher,
    getVouchers
}