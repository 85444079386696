import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import SomethingWentWrong from 'components/ErrorPage/SomethingWentWrong';
import NotFound from 'components/ErrorPage/NotFound';
import AppLayout from 'components/Layouts/AppLayout';
import Spinner from 'components/Spinner';
// Pages
import Login from '../pages/Auth/Login';
import CreateAccount from '../pages/Auth/CreateAccount';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import AuthLayout from 'components/Layouts/AuthLayout';
// Lazy loaded pages
const ComingSoon = lazy(() => import('../pages/ComingSoon'));
const Home = lazy(() => import('../pages/Home'));
const Airtime = lazy(() => import('../pages/Utilities/Airtime'));
const Data = lazy(() => import('../pages/Utilities/Data'));
const Cable = lazy(() => import('../pages/Utilities/Cable'));
const Electricity = lazy(() => import('../pages/Utilities/Electricity'));
const Betting = lazy(() => import('../pages/Utilities/Betting'));
const Internet = lazy(() => import('../pages/Utilities/Internet'));
const Vouchers = lazy(() => import('../pages/Utilities/Vouchers'));
const Education = lazy(() => import('../pages/Utilities/Education'));
const Settings = lazy(() => import('../pages/Account/Settings/Settings'));
const ChangePassword = lazy(() => import('../pages/Account/Settings/ChangePassword'));
const ChangeTransactionPin = lazy(() => import('../pages/Account/Settings/ChangeTrasactionPin'));
const TwoFactorAuthentication = lazy(() => import('../pages/Account/Settings/TwoFactorAuthentication'));
const DisableAccount = lazy(() => import('../pages/Account/Settings/DisableAccount'));
const DeleteAccount = lazy(() => import('../pages/Account/Settings/DeleteAccount'));
const Profile = lazy(() => import('../pages/Account/Profile/Profile'));
const AccountVerification = lazy(() => import('../pages/Account/Profile/AccountVerification'));
const ChangePhoneNumber = lazy(() => import('../pages/Account/Profile/ChangePhoneNumber'));
const Account = lazy(() => import('../pages/Account'));
const Tiers = lazy(() => import('../pages/Account/Profile/Tiers'));
const Cards = lazy(() => import('../pages/Cards/VirtualCard'));
const Schedule = lazy(() => import('../pages/Account/Schedule/Schedule'));
const Transaction = lazy(() => import('../pages/Transaction/Transaction'));
const Analytics = lazy(() => import('../pages/Transaction/Analytics'));
const Referral = lazy(() => import('../pages/Referral'));
const CardDetails = lazy(() => import('../pages/Cards/CardDetails'));
const Guest = lazy(() => import('pages/Guests'));
const GuestAirtime = lazy(() => import('pages/Guests/Airtime'));
const GuestData = lazy(() => import('pages/Guests/Data'));
const GuestElectricity = lazy(() => import('pages/Guests/Electricity'));
const GuestInternet = lazy(() => import('pages/Guests/Internet'));
const GuestCable = lazy(() => import('pages/Guests/Cable'));
const GuestBetting = lazy(() => import('pages/Guests/Betting'));
const Notification = lazy(() => import('../pages/Notification'));
const RequestMoney = lazy(() => import('pages/Account/RequestMoney'));

const router = createBrowserRouter([
  {
    path: '',
    errorElement: <SomethingWentWrong />,
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: '/',
        element: <ComingSoon/>
      }
    ],
    // children: [
    //   {
    //     path: '/',
    //     element: <Home />,
    //   },
    //   {
    //     path: 'airtime',
    //     element: <Airtime />,
    //   },
    //   {
    //     path: 'data',
    //     element: <Data />,
    //   },
    //   {
    //     path: 'cable',
    //     element: <Cable />,
    //   },
    //   {
    //     path: 'electricity',
    //     element: <Electricity />,
    //   },
    //   {
    //     path: 'betting',
    //     element: <Betting />,
    //   },
    //   {
    //     path: 'internet',
    //     element: <Internet />,
    //   },
    //   {
    //     path: 'vouchers',
    //     element: <Vouchers />,
    //   },
    //   {
    //     path: 'education',
    //     element: <Education />,
    //   },
    //   {
    //     path: 'transactions',
    //     element: <Transaction />,
    //   },
    //   {
    //     path: 'card',
    //     element: <Cards />,
    //   },
    //   {
    //     path: 'card/:id/:currency',
    //     element: <CardDetails />,
    //   },
    //   {
    //     path: 'analytics',
    //     element: <Analytics />,
    //   },
    //   {
    //     path: 'account',
    //     element: <Account />,
    //   },
    //   {
    //     path: 'account/referral',
    //     element: <Referral />,
    //   },
    //   {
    //     path: 'account/request-money',
    //     element: <RequestMoney />,
    //   },
    //   {
    //     path: 'account/settings',
    //     element: <Settings />,
    //   },
    //   {
    //     path: 'account/settings/change-password',
    //     element: <ChangePassword />,
    //   },
    //   {
    //     path: 'account/settings/change-transaction-pin',
    //     element: <ChangeTransactionPin />,
    //   },
    //   {
    //     path: 'account/settings/2FA-authentication',
    //     element: <TwoFactorAuthentication />,
    //   },
    //   {
    //     path: 'account/settings/disable-account',
    //     element: <DisableAccount />,
    //   },
    //   {
    //     path: 'account/settings/delete-account',
    //     element: <DeleteAccount />,
    //   },
    //   {
    //     path: 'account/scheduled-payments',
    //     element: <Schedule />,
    //   },
    //   {
    //     path: 'account/profile',
    //     element: <Profile />,
    //   },
    //   {
    //     path: 'account/profile/change-phone-number',
    //     element: <ChangePhoneNumber />,
    //   },
    //   {
    //     path: 'account/profile/tiers',
    //     element: <Tiers />,
    //   },
    //   {
    //     path: 'account/profile/verification',
    //     element: <AccountVerification />,
    //   },
    //   {
    //     path: 'notification',
    //     element: <Notification />,
    //   },
    // ],
  },
  {
    path: '/',
    errorElement: <SomethingWentWrong />,
    element: (
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'login',
        // element: <Login />,
        element: <ComingSoon/>
      },
      {
        path: 'create-account',
        // element: <CreateAccount />,
        element: <ComingSoon/>
      },
      {
        path: 'forgot-password',
        // element: <ForgotPassword />,
        element: <ComingSoon/>
      },
    ],
  },
  // {
  //   path: '/guests',
  //   errorElement: <SomethingWentWrong />,
  //   element: (
  //     <AuthLayout
  //       left={true}
  //       right={
  //         <Suspense fallback={<Spinner />}>
  //           <Outlet />
  //         </Suspense>
  //       }
  //     />
  //   ),
  //   children: [
  //     {
  //       path: '',
  //       element: <Guest />,
  //     },
  //     {
  //       path: 'airtime',
  //       element: <GuestAirtime />,
  //     },
  //     {
  //       path: 'data',
  //       element: <GuestData />,
  //     },
  //     {
  //       path: 'cable',
  //       element: <GuestCable />,
  //     },
  //     {
  //       path: 'electricity',
  //       element: <GuestElectricity />,
  //     },
  //     {
  //       path: 'internet',
  //       element: <GuestInternet />,
  //     },
  //     {
  //       path: 'betting',
  //       element: <GuestBetting />,
  //     },
  //   ],
  // },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export { router };
