import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
// Custom Hooks
import useAuth from "../../hooks/fetch/useAuth"
import useValidateFormData from "hooks/useValidateFormData"
// Components
import Header from "../Header/Header"
import Button from "../Button/Button"
import Input from "../Input/Input"
import FormWrapper from "../FormWrapper"

export default function CreateTagForm({ callback }) {
    const { validateFormData } = useValidateFormData()
    const { loading } = useSelector(state => state.auth)
    const [formData, setFormData] = useState({
        tag: ''
    })

    const { create } = useAuth()

    const disabled = useMemo(() => {
        return !validateFormData({tag: formData.tag})
    },[formData.tag])
 
    return(
        <>
            <FormWrapper
                className="mx-auto w-full sm:w-[70%] pt-70 sm:pt-128 lg:mx-0 transition_left" 
                onSubmit={() => create(formData, callback)}
            >
                <Header 
                    title="Create Poucher Tag"
                    variant="form"
                    description="Create your unique poucher tag to easily receive money anywhere."
                />
                
                <div className="mb-8">
                    <Input
                        label="Poucher Tag"
                        type="text"
                        id="tag"
                        name="tag"
                        readOnly={loading}
                        value={formData.tag}
                        onChange={(e) => setFormData({...formData, tag: e.target.value.trim()})}
                    />
                </div>
                <Button 
                    name="Create Tag"
                    theme="dark"
                    disabled={loading || disabled}
                    loading={loading}
                    styles="my-4 w-full"
                />
            </FormWrapper>
        </>
    )
}

CreateTagForm.propTypes = {
    callback: PropTypes.func
}