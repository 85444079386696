import React from "react";
import pageNotFound from "assets/images/pageNotFound.svg"
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate()
    
    return (
        <div>
            <div className="absolute top-[40px] left-[16px] w-fit">
                <button
                    className="flex items-center bg-smoke px-[16px] py-[4px] rounded-[20px] font-dmsans_r text-16 text-dark_blue hide_tap"
                    onClick={() => navigate(-1)}
                >
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0">
                        <path d="M8 15L1 8L8 1" stroke="#060628" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className="ml-3">Back</span>
                </button>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-center h-screen p-[16px]">
                <div className="max-w-[480px]">
                    <p className="text-16 text-dmsans_m text-purple">404 error</p>
                    <h1 className="text-40 sm:text-60 font-dmsans_b leading-[50px] sm:leading-[72px] mb-[24px]">Page not found...</h1>
                    <p className="text-16 sm:text-20 font-dmsans_r text-[#667085]">Sorry, the page you are looking for doesn&apos;t exist or has been moved.</p>
                </div>
                <div>
                    <img
                        src={pageNotFound}
                        alt="page not found"
                    />
                </div>
            </div>
        </div>
    )
}