import { createAsyncThunk } from '@reduxjs/toolkit'
import { auth } from '../../../services/endpoints'
import instance from 'services/axios-instance'

const customAsyncThunk = (thunkName, method, url) => createAsyncThunk(thunkName, async (payload) => {
    try {
        const body = { ...payload?.payload }
        const params = payload?.params ? payload?.params : ''
        const response = await instance[method || 'get'](`${url}/${params}`, body)
        if (response?.data) return response?.data ? response?.data : {}
        if (response?.response?.data) return response?.response?.data ? response?.response?.data : {}
    } catch (error) {
        return error
    }
})


const login = customAsyncThunk('auth/login', 'post', auth.login)
const refreshToken = customAsyncThunk('auth/refreshToken', 'put', auth.refreshToken)
const createAccount = customAsyncThunk('auth/createAccount', 'post', auth.create_account)
const createTag = customAsyncThunk('auth/createTag', 'post', auth.create_tag)
const forgotPassword = customAsyncThunk('auth/forgotPassword', 'post', auth.forgot_password)
const resetPassword = customAsyncThunk('auth/resetPassword', 'put', auth.reset_password)
const validatePasswordResetToken = customAsyncThunk('auth/validatePasswordResetToken', 'post', auth.validate_password_reset_code)
const validateOtp = customAsyncThunk('auth/validateOtp', 'post', auth.validate_otp)
const changePassword = customAsyncThunk('auth/changePassword', 'patch', auth.change_password)
const requestPasswordChange = customAsyncThunk('auth/requestPasswordChange', 'patch', auth.request_password_change)
const confirmPassword = customAsyncThunk('auth/confirmPassword', 'post', auth.confirm_password)
const createPin = customAsyncThunk('auth/createPin', 'post', auth.create_pin)
const fetchSecurityQuestions = customAsyncThunk('auth/fetchSecurityQuestions', 'get', auth.security_questions)
const fetchUserSelectedQuestions = customAsyncThunk('auth/fetchUserSelectedQuestions', 'get', auth.user_questions)
const createSecurityQuestion = customAsyncThunk('auth/createSecurityQuestion', 'post', auth.create_security_question)
const validateSecurityAnswer = customAsyncThunk('auth/validateSecurityAnswer', 'post', auth.validate_2fa)
const validatePin = customAsyncThunk('auth/validatePin', 'post', auth.validatePin)
const verifyAccount = customAsyncThunk('auth/verifyAccount', 'post', auth.verify_account)
const resendOtp = customAsyncThunk('auth/resendOtp', 'post', auth.resendOtp)
const resendVerificationEmail = customAsyncThunk('auth/resendVerificationEmail', 'post', auth.resend_verification_email)
const logoutModalFunc = createAsyncThunk('auth/logout/modal/func', async (payload, thunkAPI) => {
    try {
        const response = await instance['post'](`auth/logout`)
        if (response?.data?.code === 200 || response?.data?.code === 401) {
            thunkAPI.dispatch({ type: 'logout'})
        }
    } catch (error) {
        return error
    }
})
const logout = createAsyncThunk('auth/logout', async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: 'logout'})
})



export {
    login,
    logout,
    logoutModalFunc,
    createAccount,
    forgotPassword,
    resetPassword,
    verifyAccount,
    createTag,
    validatePasswordResetToken,
    validateOtp,
    resendVerificationEmail,
    refreshToken,
    changePassword,
    requestPasswordChange,
    confirmPassword,
    createPin,
    validatePin,
    fetchSecurityQuestions,
    fetchUserSelectedQuestions,
    createSecurityQuestion,
    validateSecurityAnswer,
    resendOtp
}