import React from "react";
import PropTypes from "prop-types"

export default function Analysis({ color }) {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="1">
            <rect width="20" height="20" fill="white"/>
            <path d="M8.46095 4.62962C8.50339 4.716 8.53143 4.80852 8.54398 4.90362L8.77601 8.35356L8.89119 10.0876C8.89238 10.2659 8.92035 10.4431 8.97421 10.6134C9.11332 10.9438 9.44796 11.1538 9.81203 11.1392L15.3598 10.7763C15.6 10.7723 15.832 10.8622 16.0047 11.0261C16.1486 11.1627 16.2415 11.3413 16.2708 11.5335L16.2806 11.6502C16.051 14.8291 13.7163 17.4806 10.544 18.165C7.37162 18.8495 4.11855 17.4036 2.55092 14.6124C2.09898 13.8015 1.8167 12.9102 1.72064 11.9908C1.68052 11.7187 1.66285 11.4438 1.66781 11.1688C1.66285 7.76062 4.08988 4.81414 7.48727 4.10381C7.89617 4.04015 8.29701 4.25661 8.46095 4.62962Z" fill={color || "#060628"}/>
            <path opacity="1" d="M10.7251 1.66743C14.525 1.7641 17.7187 4.49657 18.3335 8.17699L18.3276 8.20414L18.3108 8.24364L18.3132 8.35205C18.3045 8.49568 18.249 8.63387 18.1534 8.74549C18.0539 8.86176 17.9179 8.94094 17.7681 8.97167L17.6768 8.98421L11.2761 9.39892C11.0632 9.41992 10.8513 9.35127 10.6929 9.21004C10.561 9.09236 10.4766 8.93349 10.4528 8.76231L10.0232 2.37097C10.0157 2.34936 10.0157 2.32593 10.0232 2.30432C10.0291 2.12814 10.1066 1.96161 10.2385 1.84194C10.3704 1.72226 10.5457 1.65941 10.7251 1.66743Z" fill={color || "#060628"}/>
            </g>
        </svg>
    )
}

Analysis.propTypes = {
    color: PropTypes.string
};