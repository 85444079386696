import { useEffect, useState } from "react"

export default function useResendOtp() {
    const [timeLeft, setTimeLeft] = useState(50)

    const resetTimer = () => {
        setTimeLeft(50)
    }

    useEffect(() => {
        let timer = setInterval(() => {
            if (timeLeft !== 0) setTimeLeft(prevState => prevState - 1)
        }, 1000)

        return () => clearInterval(timer)
    },[timeLeft])

    return { timeLeft, resetTimer }
}