const base = '/payment'

export const payment = {
    request_money: `${base}/request-money`,
    transfer_money_p2p: `${base}/p2p-transfer`,
    transfer_money_local_bank: `${base}/local-bank-transfer`,
    get_local_bank_account_details: `${base}`,
    get_all_banks: `${base}/banks`,
    get_all_guest_user_banks: `guest-user/banks`,
    get_wallet_balance: `${base}/wallet`,
    schedule_p2p_transfer: `${base}/p2p/schedule`,
    schedule_local_transfer: `${base}/local-bank-transfer/schedule`,
    delete_schedule: `${base}/schedule`,
    check_payment_status: `${base}/status`,
    env: `/admin-settings/envs`,
    requests: `${base}/requests`,
    request: `${base}/request`,
    get_all_beneficiaries: `${base}/beneficiaries`,
    add_beneficiary: `${base}/beneficiaries`,
    delete_beneficiary: `${base}/beneficiaries`,
}