import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notify } from "../../store/modules/global/index"
import GreenThick from "../../assets/icons/GreenThick";
import Close from "../../assets/icons/Close";
import { AnimatePresence, motion } from "framer-motion";
import { logout } from "store/modules/auth/actions";
import { INVALID_MESSAGE_LIST } from "constants";

export default function Notification() {
    let dispatch = useDispatch()
    const { notification } = useSelector(state => state.global)


    const closeFirst = () => {
        if (notification?.length) dispatch(notify({ removeFirst: true }))
    }

    const closeLast = () => {
        if (notification?.length) dispatch(notify({ removeLast: true }))
    }

    // const closeNotification = (item) => {
    //     const newNotificationList = notification?.filter((data) => item?.message !== data?.message && item?.status !== data?.status && item.display !== data?.true)
    //     if (notification?.length) dispatch(notify({ removeLast: true }))
    // }

    useEffect(() => {
        let timer = setTimeout(() => {
            closeFirst()
        }, 5000)

        return () => clearTimeout(timer)
    }, [notification])

    const vetUser = () => {
        const validate = notification?.filter((item) => {
            return item?.status === 'error' && INVALID_MESSAGE_LIST.includes(item?.message)
        })
        if (validate.length > 0) {
            dispatch(logout())
        }
    }
    useEffect(() => {
        vetUser()
    },[notification])



    return (
        <AnimatePresence>
            {/* <div className="fixed top-[1rem] right-[1rem] z-[1000] max-w-[91.5%] flex flex-col gap-2 transition-all">
            </div> */}
            {notification?.map((item, i) =>
                <motion.div
                    key={i + 1}
                    animate={{ opacity: 1, y: '0px' }}
                    exit={{ opacity: 0, y: '-20px' }}
                    initial={{ opacity: 0, y: '-20px' }}
                    data-testid="notification"
                    id="notification"
                    className={
                        `${item?.status === 'success' ? 'bg-green_notification border-green' : ''}
                        ${item?.status === 'error' ? 'bg-[#FFEBEB] border-[#FF0000]' : ''}
                        ${item?.status === 'notify' ? 'bg-[#000000] border-[#000000]' : ''}
                        transition-all border rounded-lg flex items-center p-[1rem] sm:p-[1.25rem]
                        fixed top-[1rem] right-[1rem] z-[1000] max-w-[91.5%] `
                    }
                >
                    {item?.status === 'success' && <GreenThick />}
                    <p
                        className={
                            `${item?.status === 'success' ? 'text-green' : ''}
                    ${item?.status === 'error' ? 'text-[#FF0000]' : ''}
                    ${item?.status === 'notify' ? 'text-white' : ''}
                    font-dmsans_m font-medium text-14 ml-[1rem] mr-[2rem]`
                        }
                    >
                        {item?.message}
                    </p>
                    <div
                        className="cursor-pointer hide_tap"
                        onClick={() => closeLast()}
                    >
                        <Close
                            color={item?.status === 'success' ? 'green' : item?.status === 'error' ? 'red' : 'white'}
                        />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}