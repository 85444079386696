const reducers = {
  notify: (state, action) => {
    if (action?.payload.removeFirst === true) {
      state.notification = [...state.notification.slice(1, state.notification.length)]
      return
    }
    if (action?.payload.removeLast === true) {
      state.notification = [...state.notification.slice(0, (state.notification.length - 1))]
      return
    }
    state.notification = [
      ...state.notification,
      {
        display: true,
        status: action?.payload?.status,
        message: action?.payload?.message,
      }
    ]
  },
  toggleBalance: (state, action) => {
    state.showBalance = action?.payload;
  }
};

export { reducers };
