import {
  login,
  refreshToken,
  createAccount,
  verifyAccount,
  validatePasswordResetToken,
  forgotPassword,
  resetPassword,
  createPin,
  fetchSecurityQuestions,
  fetchUserSelectedQuestions,
} from './actions';

const setUser = (state, action) => {
  state.signedUpUser = {};
  if(action?.payload?.data) {
    state.token = action?.payload?.data?.token;
    state.loggedUser = action?.payload?.data;
    state.loginSuccess = action?.payload?.status;
    state.isLoggedIn = action?.payload?.status === 'success' ? true : false;
  }
}

const extraReducers = {
  // Login
  [login.fulfilled]: (state, action) => setUser(state, action),
  // Refresh token
  [refreshToken.fulfilled]: (state, action) => setUser(state, action),
  // Create account
  [createAccount.fulfilled]: (state, action) => {
    if(action?.payload?.data) {
      state.signedUpUser = action?.payload?.data;
      state.signupSuccess = action?.payload?.status;
      state.isSignedUp = action?.payload?.status === 'success' ? true : false;
    }
  },
  // Create pin
  [createPin.fulfilled]: (state, action) => {
    if(action?.payload?.data) {
      state.loggedUser.tag = action?.payload?.data?.tag;
      state.loggedUser.iscreatedpin = action?.payload?.data?.iscreatedpin;
      state.loginSuccess = action?.payload?.status;
      state.isLoggedIn = action?.payload?.status === 'success' ? true : false;
    }
  },
  // Verify account
  [verifyAccount.fulfilled]: (state, action) => {
    if(action?.payload?.data) {
      state.token = action?.payload?.data?.token;
      state.signedUpUser = action?.payload?.data;
      state.signupSuccess = action?.payload?.status;
    }
  },
  // Forgot password
  [forgotPassword.fulfilled]: (state, action) => {
    if(action?.payload?.data) state.recoverUser = action?.payload?.data;
  },
  // Validate Password Reset Token
  [validatePasswordResetToken.fulfilled]: (state, action) => {
    if(action?.payload?.data) state.recoverUser = action?.payload?.data;
  },
  //Reset Password
  [resetPassword.fulfilled]: (state) => {
    state.loggedUser = {};
  },
  // Security Questions
  [fetchSecurityQuestions.fulfilled]: (state, action) => {
    if(action?.payload?.data) state.securityQuestions = action?.payload?.data;
  },
  // Create Security Questions
  [fetchUserSelectedQuestions.fulfilled]: (state, action) => {
    if(action?.payload?.data) state.userSelectedQuestions = action?.payload?.data
  },
};

export { extraReducers };
