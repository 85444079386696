export const MIXPANEL_USERLOGGEDIN = "userLoggedIn";
export const MIXPANEL_USERSIGNEDUP = "userSignedUp";
export const MIXPANEL_USERCLICKSCOMPLETEDPAYMENTBUTTON = "userClicksCompletedPaymentButton";
export const MIXPANEL_TRANSFERMONEYP2P = "transferMoneyP2P";
export const MIXPANEL_LOCALBANKTRANSFER = "localBankTransfer";
export const MIXPANEL_USERREQUESTEDMONEY = "userRequestedMoney";
export const MIXPANEL_USERBOUGHTAIRTIME = "userBoughtAirtime";
export const MIXPANEL_USERSCHEDULEDAIRTIMEPURCHASE = "userScheduledAirtimePurchase";
export const MIXPANEL_USERBOUGHTDATA = "userBoughtData";
export const MIXPANEL_SCHEDULEDDATAPURCHASE = "userScheduledDataPurchase";
export const MIXPANEL_USERBOUGHTELECTRICITY = "userBoughtElectricity";
export const MIXPANEL_SCHEDULEDELECTRICITY = "userScheduledElectricity";
export const MIXPANEL_BOUGHTINTERNET = "userBoughtInternet";
export const MIXPANEL_SCHEDULEDINTERNETSUBSCRIPTION = "userScheduledInternetSubscription";
export const MIXPANEL_USERREFILLEDCABLETVSUBSCRIPTION = "userRefilledCableTVSubscription";
export const MIXPANEL_USERSCHEDULEDCABLETVPURCHASE = "userScheduledCableTVPurchase";
export const MIXPANEL_USERBOUGHTEDUCATION = "userBoughtEducation";
export const MIXPANEL_USERBOUGHTBETTING = "userBoughtBetting";
export const MIXPANEL_BOUGHTVOUCHER = "userBoughtVoucher";
export const MIXPANEL_SENTVOUCHER = "userSentVoucher";
export const MIXPANEL_REDEEMEDVOUCHER = "userRedeemedVoucher";
export const MIXPANEL_FUNDVIRTUALCARD = "fundVirtualCard";
export const MIXPANEL_FREEZEVIRTUALCARD = "freezeVirtualCard";
export const MIXPANEL_USERSUBMITTEDKYC = "userSubmittedKKyc";
export const MIXPANEL_ENTERPROFILESCREEN = "enterProfileScreen"