import { createSlice } from '@reduxjs/toolkit'
import { extraReducers } from './reducers'

const initialState = {
  profile: {},
  tiers: {},
  banners: [],
  twoFactorAuthentication: {},
  notifications: {},
  referrals: {},
  loading: false,
  error: null
}

export const user = createSlice({
  name: 'user',
  initialState,
  extraReducers
})

export default user.reducer