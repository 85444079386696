import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Constants
import { nav_links, mobile_nav_links } from "../../constants/navLinks";
// Components
import Logo from "../../assets/icons/Logo";
import NotificationIcon from "../../assets/icons/Notification";
import Avatar from "../Avatar/Avatar";
import useUser from "hooks/fetch/useUser";
import { LogoutModal } from "components/LogoutModal";

export default function NavBar() {
    const [showModal, setShowModal] = useState(false)

    let ref = useRef()
    const [active, setActive] = useState('')
    const { profile } = useSelector(state => state.user)
    const { loggedUser } = useSelector(state => state.auth)
    let pathname = window.location.pathname.split('/')[1]
    const [dropmenu, setDropmenu] = useState(false)
    const { get_notifications } = useUser()
    const { notifications, loading = true } = useSelector(state => state.user)
    useEffect(() => {
        get_notifications({ params: `?page=1&per_page=${100}` })
    },[])
    const toggleShowModal = () => {
        setDropmenu(false)
        setShowModal(!showModal)
    }

    useEffect(() => {
        if (!['transactions', 'card', 'account', 'analytics'].includes(pathname)) setActive('home')
        if (pathname.match('transactions')) setActive('transactions')
        if (pathname.match('card')) setActive('card')
        if (pathname.match('account')) setActive('account')
        if (pathname.match('analytics')) setActive('analytics')
    },[pathname])

    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (ref && (!ref.current || ref.current.contains(event.target))) return;
      
            setDropmenu(false);
        };
      
        document.addEventListener('mousedown', listener);
    
        return () => document.removeEventListener('mousedown', listener);
    }, [])




    return(
        <>
        {/* Navbar for Coming Soon Page */}
            {/* <div
                className="w-full left-0 bg-white z-30 fixed top-0 lg:navbar-shadow
                h-[72px] md:h-[90px] flex items-center justify-center">
                    <div className="flex items-center mx-auto
                    lg:!pb-0 lg:!pt-0 lg:!px-0 xl:!ml-[160px] 2xl:!mx-auto 2xl:!pl-0 max-w-[1440px]
                        px-24 w-full md:w-3/4 lg:w-full xl:w-full 2xl:h-full 2xl:w-full lg:!pl-10 xl:!pl-0 xl:max-w-[1440px]"
                    >
                        <img src={PouchersLogo} alt="Pouchers" className="w-[100.086px] h-[24px] md:w-[116.767px] md:h-[28px]" />
                    </div>
            </div> */}
            <div className="w-full bg-white z-30 fixed lg:top-0 bottom-0 lg:navbar-shadow lg:h-[86px]">
                <header className="flex justify-between sm:z-10 lg:w-[90%] mx-auto">
                    <div className="hidden lg:flex items-center">
                        <Logo />
                    </div>
                    <nav className="w-full lg:flex-[.6] lg:w-auto">
                        <ul className="hidden lg:flex justify-center items-center h-full ml-[3.25rem]">
                            {
                                nav_links.map((link) => 
                                <li key={link.id}>
                                    <NavLink 
                                        to={link.url} 
                                        style={{
                                            borderBottom: link?.title.toLowerCase().match(active) && '1px solid #5034C4',
                                            color: link?.title.toLowerCase().match(active) && '#5034C4',
                                            opacity: link?.title.toLowerCase().match(active) ? '1' : '0.4'
                                        }}
                                        className="flex items-center py-32 px-16 transition duration-700 ease-in-out"
                                    >
                                        {link.icon}
                                        <p className="ml-[10px] font-dmsans_b text-14">{link.title}</p>
                                    </NavLink>
                                </li>
                                )
                            }
                        </ul>
                        <ul className="flex justify-between lg:hidden items-center w-full menu-shadow">
                            {
                                mobile_nav_links.map((link) => 
                                <li key={link.id}>
                                    <NavLink 
                                        to={link.url} 
                                        style={{
                                            color: link?.title.toLowerCase().match(active) && '#5034C4',
                                            opacity: link?.title.toLowerCase().match(active) ? '1' : '0.4'
                                        }}
                                        className="flex flex-col items-center py-3 px-22 lg:py-32 lg:px-16 hide_tap"
                                    >
                                        {link.icon}
                                        <p className="mt-[.2rem] font-dmsans_b text-10">{link.title}</p>
                                    </NavLink>
                                </li>
                                )
                            }
                        </ul>
                    </nav>
                    <div className="hidden lg:flex items-center">
                        <Link to="/notification">
                            <NotificationIcon
                                status={notifications && notifications.unreadCount > 0 ? true : false}
                            />
                        </Link>
                        <div className="flex items-center ml-[1.938rem] relative">
                            <Link to="/account/profile" className="flex items-center">
                                <Avatar
                                    url={profile?.profile_picture || loggedUser?.profile_picture}
                                    width="40px"
                                    height="40px"
                                    alt={profile?.first_name?.split('')[0]}
                                    initials={`${profile?.first_name?.split('')[0] || ''}${profile?.last_name?.split('')[0] || ''}`}
                                    className="mr-2 shrink-0"
                                />
                                <p className="font-dmsans_b text-14 capitalize">
                                    {profile?.first_name || loggedUser?.first_name} {profile?.last_name || loggedUser?.last_name}
                                </p>
                            </Link>
                            <svg 
                                data-testid="drop"
                                width="10" 
                                height="7" 
                                viewBox="0 0 10 7" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg" 
                                className="ml-[16px] cursor-pointer"
                                onClick={() => setDropmenu(!dropmenu)}
                            >
                                <path d="M1.175 0.158447L5 3.97511L8.825 0.158447L10 1.33345L5 6.33345L0 1.33345L1.175 0.158447Z" fill="#060628"/>
                            </svg>
                            {
                                dropmenu &&
                                <div 
                                    ref={ref}
                                    data-testid='dropmenu'
                                    className="absolute bg-white w-[170px] top-[50px] rounded-[11px] 
                                    py-[34px] px-[24px] dropmenu-shadow font-dmsans_b text-14 transition_left"
                                >
                                    <Link to="/account/settings">
                                        <p className="mb-[20px] w-fit" onClick={() => setDropmenu(false)}>Account Settings</p>
                                    </Link>
                                    <button
                                        type="button"
                                        className="w-fit text-red"
                                        onClick={toggleShowModal}
                                    >
                                        Log Out
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </header>
            </div>
            {showModal && <LogoutModal toggleShowModal={toggleShowModal} />}
        </>
    )
}
