import React from "react";
import somethingWentWrong from "assets/images/somethingWentWrong.svg"

export default function SomethingWentWrong() {
    return(
        <div className="flex flex-col sm:flex-row items-center justify-center h-screen p-[16px]">
            <div className="max-w-[480px]">
                <h1 className="text-40 sm:text-60 font-dmsans_b leading-[50px] sm:leading-[72px] mb-[24px]">Something went wrong...</h1>
                <p className="text-16 sm:text-20 font-dmsans_r text-[#667085]">Please check your internet connection and reload the page.</p>
            </div>
            <div>
                <img
                    src={somethingWentWrong}
                />
            </div>
        </div>
    )
}