import React from "react";
import PropTypes from "prop-types"

export default function Upload({theme}) {
    return(
        <>
        {
            theme === 'dark' ?
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-[8px]">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.76002 4.79859C7.55554 5.07476 7.57902 5.4687 7.83736 5.7187C8.11236 5.9937 8.56152 5.9937 8.83652 5.7187L10.294 4.25203V8.04703H11.7057V4.25203L13.1632 5.7187L13.2422 5.78688C13.5184 5.99142 13.9124 5.9687 14.1624 5.7187C14.2999 5.5812 14.3732 5.39786 14.3732 5.21453C14.3732 5.04036 14.2999 4.85703 14.1624 4.71953L11.4949 2.04286L11.4117 1.96814C11.2932 1.87896 11.1465 1.83203 10.9999 1.83203C10.8074 1.83203 10.6332 1.90536 10.4957 2.04286L7.82819 4.71953L7.76002 4.79859ZM5.71157 8.05173C3.55443 8.15361 1.83301 9.96822 1.83301 12.1789V16.7302L1.83744 16.925C1.9376 19.1203 3.7216 20.8805 5.91217 20.8805H16.0963L16.2878 20.876C18.4449 20.7741 20.1663 18.9593 20.1663 16.7396V12.1975L20.1619 12.002C20.0612 9.79866 18.2688 8.04721 16.0872 8.04721H11.7055V13.6431L11.699 13.7415C11.6516 14.0948 11.3526 14.3613 10.9997 14.3613C10.6055 14.3613 10.2938 14.0442 10.2938 13.6431V8.04721H5.90301L5.71157 8.05173Z" fill="#5034C4"/>
            </svg> :
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.38948 7.98354H4.45648C2.42148 7.98354 0.771484 9.63354 0.771484 11.6685L0.771484 16.5435C0.771484 18.5775 2.42148 20.2275 4.45648 20.2275H15.5865C17.6215 20.2275 19.2715 18.5775 19.2715 16.5435V11.6585C19.2715 9.62954 17.6265 7.98354 15.5975 7.98354L14.6545 7.98354" stroke="#5034C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.0215 1.19044V13.2314" stroke="#5034C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.10547 4.11816L10.0205 1.19016L12.9365 4.11816" stroke="#5034C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        }
        </>
    )
}

Upload.propTypes = {
    theme: PropTypes.string
}