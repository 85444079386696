import React from "react";
import PropTypes from "prop-types"

export default function Card({ color }) {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="1">
            <path d="M20 15C20 15.663 19.7366 16.2989 19.2678 16.7678C18.7989 17.2366 18.163 17.5 17.5 17.5H2.5C1.83696 17.5 1.20107 17.2366 0.732233 16.7678C0.263393 16.2989 0 15.663 0 15V8.75H20V15ZM5.625 13.75C5.79076 13.75 5.94973 13.6842 6.06694 13.5669C6.18415 13.4497 6.25 13.2908 6.25 13.125V11.875C6.25 11.7092 6.18415 11.5503 6.06694 11.4331C5.94973 11.3158 5.79076 11.25 5.625 11.25H3.125C2.95924 11.25 2.80027 11.3158 2.68306 11.4331C2.56585 11.5503 2.5 11.7092 2.5 11.875V13.125C2.5 13.2908 2.56585 13.4497 2.68306 13.5669C2.80027 13.6842 2.95924 13.75 3.125 13.75H5.625ZM20 6.25V5C20 4.33696 19.7366 3.70107 19.2678 3.23223C18.7989 2.76339 18.163 2.5 17.5 2.5H2.5C1.83696 2.5 1.20107 2.76339 0.732233 3.23223C0.263393 3.70107 0 4.33696 0 5V6.25H20Z" fill={color || "#060628"}/>
            </g>
        </svg>
    )
}

Card.propTypes = {
    color: PropTypes.string
};