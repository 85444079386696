import { createSlice } from '@reduxjs/toolkit'
import { extraReducers, reducers } from './reducers'

const initialState = {
    cards: undefined,
    cardDetails: [],
    cardTransactionHistory: [],
    virtualCardBalance: {},
    virtualAccounts: [],
    loading: false,
    error: null
}

export const card = createSlice({
  name: 'card',
  initialState,
  reducers,
  extraReducers
})
// Action creators are generated for each case reducer function
export const { resetCards } = card.actions

export default card.reducer