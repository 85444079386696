import { getVouchers } from "./actions";

const extraReducers = {
    // get all vouchers
    [getVouchers.fulfilled]: (state, action) => {
        if(action?.payload?.data) state.vouchers = action?.payload?.data
    }
};

export { extraReducers };
  