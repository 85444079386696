import { createSlice } from '@reduxjs/toolkit'
import { extraReducers } from './reducers'

const initialState = {
  banks: [],
  envs: [],
  wallet: {},
  loading: false,
  error: null,
  beneficiaries: {}
}

export const payment = createSlice({
  name: 'payment',
  initialState,
  extraReducers
})

export default payment.reducer