import React, { useCallback, useRef } from "react"
import Button from "../Button/Button"
import PropTypes from "prop-types"
// Custom Hooks
import useAuth from "../../hooks/fetch/useAuth"
import usePIN from "../../hooks/usePIN"
// Components
import Header from "../Header/Header"
import Pin from "../Input/Pin"
import FormWrapper from "../FormWrapper"
import Label from "../Label/Label"

export default function CreateTransactionPin({ callback }) {
    const inputRef = useRef([]);
    const confirmPinRef = useRef([]);
    const [pin, onPaste, onChange, onPress] = usePIN(4, inputRef)
    const [confirmPIN, onPasteConfirm, onChangeConfirm, onPressConfirm] = usePIN(4, confirmPinRef)
    const { loading, create_pin } = useAuth()
    const formData = {
        confirm: confirmPIN,
        pin
    }

    const onSubmit = useCallback(() => {
        create_pin(formData, callback)
    },[formData])
 
    return(
        <FormWrapper
            className="mx-auto w-full sm:w-[400px] lg:mx-0 transition_left"
            onSubmit={onSubmit}
        >
            <Header 
                title="Create transaction pin"
                variant="form"
                description="Your pin secures your account. This will be required for all transactions and do not share. "
            />
            <Label title='Enter Pin'/>
            <Pin
                id='pin'
                name='pin'
                type="text"
                pin={pin}
                inputRef={inputRef}
                onChange={onChange}
                onKeyDown={onPress}
                onPaste={onPaste}
                readOnly={loading}
                className="grid grid-cols-4 gap-4 mb-[32px]"
            />
            <Label title='Confirm Pin'/>
            <Pin
                id='confirm-pin'
                name='confirm-pin'
                type="text"
                pin={confirmPIN}
                inputRef={confirmPinRef}
                onChange={onChangeConfirm}
                onKeyDown={onPressConfirm}
                onPaste={onPasteConfirm}
                readOnly={loading}
                className="grid grid-cols-4 gap-4 mb-8"
            />
            <Button 
                name="Create Pin"
                theme="dark"
                disabled={!pin || !confirmPIN || loading}
                loading={loading}
                styles="my-4 w-full"
            />
        </FormWrapper>
    )
}

CreateTransactionPin.propTypes = {
    callback: PropTypes.func,
    description: PropTypes.string,
    userStatus: PropTypes.string
}