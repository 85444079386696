import { createSlice } from '@reduxjs/toolkit'
import { extraReducers } from './reducers'

const initialState = {
  vouchers: {},
  loading: false,
  error: null
}

export const voucher = createSlice({
  name: 'voucher',
  initialState,
  extraReducers
})

export default voucher.reducer