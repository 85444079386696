import { useAppDispatch } from "../useAppDispatch"
import { notify } from "../../store/modules/global"
import { useState } from "react"
// Actions
import {
    changeTransactionPIN,
    disableUser,
    deleteUser,
    validateBvn,
    fetchProfile,
    validateID,
    requestPhoneChange,
    changePhoneNumber,
    editProfile,
    getTiers,
    generate2faToken,
    validate2faToken,
    disable2fa,
    uploadImage,
    getUserByTag,
    getSchedules,
    getBanners,
    getNotifications,
    readNotification,
    getReferrals,
    getPaymentRequestCount,
    getAllNotifications,
    readAllNotifications,
    readAllPaymentRequest
} from "../../store/modules/user/actions"
import { getWalletBalance } from "store/modules/payment/actions"
import { logout } from "../../store/modules/auth/actions"
// Custom Hooks
import useValidateFormData from "../useValidateFormData"
import useHandleError from "../useHandleError"
import useMakeRequest from "../useMakeRequest"
import useExtractPIN from "../useExtractPIN"
import useInputValidate from "../useInputValidate"
import encryptData from "utils/encryptData"
import { MIXPANEL_USERSUBMITTEDKYC } from "constants/enum"

export default function useUser() {
    let dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const { validateFormData } = useValidateFormData()
    const { checkPin } = useInputValidate()
    const { extractPIN } = useExtractPIN()
    const { makeDispatch } = useMakeRequest()
    const { handleError } = useHandleError()
    const [showModal, setShowModal] = useState(false);

    // CHANGE PASSWORD
    const change_transaction_pin = async (formData, callback) => {
        try {
            const oldPin = extractPIN(formData.oldPin)
            const pin = extractPIN(formData.pin)
            setLoading(true)
            if (!checkPin(oldPin) || !checkPin(pin)) return // check if formData is valid)
            const value = encryptData({ oldPin, pin })
            makeDispatch({ action: changeTransactionPIN({ payload: value }), callback, alert: false })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }

    }

    // DISABLE USER
    const disable_user = async (formData) => {
        const value = encryptData({ disable_reason: formData })
        makeDispatch({
            action: disableUser({ payload: value }),
            callback: () => dispatch(logout())
        })
    }

    // DELETE USER
    const delete_user = async (formData) => {
        const value = encryptData({ delete_reason: formData })
        makeDispatch({
            action: deleteUser({ payload: value }),
            callback: () => dispatch(logout())
        })
    }

    // VALIDATE BVN
    const validate_bvn = (formData, callback) => {
        try {
            if (!validateFormData(formData)) return // check if formData is valid
            const value = encryptData({ ...formData })
            makeDispatch({ type: MIXPANEL_USERSUBMITTEDKYC, action: validateBvn({ payload: value }), callback })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // FETCH PROFILE
    const fetch_profile = async () => {
        return makeDispatch({ action: fetchProfile(), alert: false })
    }

    // FETCH WALLET BALANCE
    const get_wallet_balance = async () => {
        return makeDispatch({ action: getWalletBalance(), alert: false })
    }

    // GET USER BY TAG
    const get_user_by_tag = async (tag) => {
        return makeDispatch({ action: getUserByTag({ params: tag }), alert: false })
    }

    // VALIDATE ID
    const validate_id = (formData, callback) => {
        const value = encryptData({ ...formData })
        makeDispatch({ type: MIXPANEL_USERSUBMITTEDKYC, action: validateID({ payload: value }), callback })
    }

    // REQUEST PHONE CHANGE
    const request_phone_change = (formData, callback) => {
        const value = encryptData({ email: formData })
        makeDispatch({ action: requestPhoneChange({ payload: value }), callback })
    }

    // CHANGE PHONE NUMBER
    const change_phone_number = (formData, callback) => {
        const value = encryptData({ ...formData })
        makeDispatch({ action: changePhoneNumber({ payload: value }), callback })
            .then((res) => {
                if ([200, 201].includes(res?.code)) fetchProfile()
            })
    }

    // EDIT PROFILE
    const edit_profile = (formData, callback) => {
        try {
            if (!validateFormData(formData)) return // check if formData is valid
            const value = encryptData({ ...formData })    
            makeDispatch({ type: "utility_bill" in formData && MIXPANEL_USERSUBMITTEDKYC, action: editProfile({ payload: value }), callback })
                .then((res) => {
                    if ([200, 201].includes(res?.code)) fetchProfile()
                })
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // GET TIERS
    const get_tiers = async () => {
        makeDispatch({ action: getTiers(), alert: false })
    }

    // GET SCHEDULES
    const get_schedules = async ({ params }) => {
        return makeDispatch({ action: getSchedules({ params }), alert: false })
    }

    // GENERATE 2FA
    const generate_2fa = (callback) => {
        makeDispatch({ action: generate2faToken(), callback, alert: false })
    }

    // VALIDATE 2FA
    const validate_2fa = (formData, callback) => {
        try {
            setLoading(true)
            const pin = extractPIN(formData)
            const value = encryptData({ user2faToken: pin })
            makeDispatch({ action: validate2faToken({ payload: value }), callback, alert: true })
                .finally(() => setLoading(false))
        } catch (error) {
            dispatch(notify({ display: true, status: 'error', message: handleError({}) }))
        }
    }

    // VALIDATE 2FA
    const disable_2fa = ({ formData, callback }) => {
        const { transactionPin } = formData
        setLoading(true)
        const value = encryptData({ transactionPin })
        makeDispatch({
            action: disable2fa({ payload: value }),
            callback,
            alert: true, successMessage: 'Two Factor disabled'
        })
            .finally(() => setLoading(false))
    }

    // Get banners
    const get_banners = () => {
        makeDispatch({ action: getBanners(), alert: false })
    }

    // Get Notifications
    const get_notifications = ({ params }) => {
        return makeDispatch({ action: getNotifications({ params }), alert: false })
    }

    // Get All Notifications
    const get_all_notifications = () => {
        return makeDispatch({ action: getAllNotifications(), alert: false })
    }

    // Read Notifications
    const read_notification = ({ params }) => {
        return makeDispatch({ action: readNotification({ params }), alert: false })
    }

    const read_all_notifications = () => {
        return makeDispatch({ action: readAllNotifications(), alert: true })
    }

    const read_all_payment_request = () => {
        return makeDispatch({ action: readAllPaymentRequest(), alert: false })
    }

    // Get Payment Request Count
    const get_payment_request_count = () => {
        return makeDispatch({ action: getPaymentRequestCount(), alert: false })
    }

    // UPLOAD IMAGE
    const upload_image = (formData) => {
        makeDispatch({ action: uploadImage(formData), alert: false })
    }

    const get_referrals = (formData) => {
        setLoading(true)
        makeDispatch({ action: getReferrals(formData), alert: false })
            .finally(() => setLoading(false))
    }

    return {
        showModal,
        setShowModal,
        change_transaction_pin,
        disable_user,
        delete_user,
        makeDispatch,
        validate_bvn,
        validate_id,
        fetch_profile,
        request_phone_change,
        change_phone_number,
        edit_profile,
        get_tiers,
        generate_2fa,
        validate_2fa,
        disable_2fa,
        upload_image,
        get_user_by_tag,
        get_schedules,
        get_banners,
        get_wallet_balance,
        get_notifications,
        get_all_notifications,
        read_notification,
        read_all_notifications,
        read_all_payment_request,
        get_referrals,
        get_payment_request_count,
        loading
    }
}