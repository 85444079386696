import React from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

export default function BackButton({ onClick }) {
    const navigate = useNavigate()

    return(
        <button
            className="flex items-center sm:bg-smoke sm:px-16 py-2 rounded-[20px] font-dmsans_r text-16 text-dark_blue hide_tap"
            onClick={onClick || (() =>  navigate(-1))}
        >
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15L1 8L8 1" stroke="#060628" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <span className="hidden sm:block ml-3">Back</span>
        </button>
    )
}

BackButton.propTypes = {
    onClick: PropTypes.func,
};