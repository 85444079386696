import React from 'react'
import { useDispatch } from 'react-redux'
import { logoutModalFunc } from 'store/modules/auth/actions'
import PropTypes from "prop-types"


export const LogoutModal = ({toggleShowModal}) => {
    let dispatch = useDispatch()
    
  return (
    <div className="fixed h-full w-full inset-0 bg-[#00000030] z-40 flex items-center justify-center">
        <div className="flex flex-col gap-6 items-center justify-center w-4/5 max-w-[416px] h-4/5 max-h-[208px] bg-white rounded-xl">
            <div className="flex flex-col gap-2 items-center justify-center">
                <h2 className="text-24 font-dmsans_b leading-8 text-[#060628]">Logout</h2>
                <p className="text-base leading-6 font-dmsans_r text-[#8F8E9B]">Are you sure you want to logout?</p>
            </div>
            <div className="flex items-center justify-center gap-[45px]">
                <button
                    type="button"
                    onClick={toggleShowModal}
                    className="text-base w-fit font-dmsans_b text-[#8F8E9B] leading-6"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="text-base font-dmsans_b w-fit text-[#CE4C09] leading-6"
                    data-testid="logout"
                    onClick={() => dispatch(logoutModalFunc())}
                >
                    Log Out
                </button>
            </div>
        </div>
    </div>
  )
}

LogoutModal.propTypes = {
    toggleShowModal: PropTypes.func
}