/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function Modal({ children, position, closeModal }) {
  return (
    <div data-testid="modal">
      <div className="z-[500] overlay blur-3xl" data-testid="overlay" onClick={closeModal}></div>
      <div
        className={
          `px-7.5 bg-white md:py-[40px] md:px-[29.5px] p-6 
          rounded-2xl w-[90%] max-w-[416px] overflow-y-auto
          md:h-max ${position === 'bottom' ? 'bottom_modal' : 'modal'} modal_transition`
        }
      >
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  closeModal: PropTypes.any,
  position: PropTypes.string,
  children: PropTypes.element
};
