import React from 'react';
import { RouterProvider } from 'react-router-dom';
import Notification from './components/Notification/Notification';
import { router } from './router';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <div className="relative sm:bg-authBg bg-fixed bg-no-repeat bg-right-bottom">
      <HelmetProvider>
        <Notification />
        <RouterProvider router={router} />
      </HelmetProvider>
    </div>
  );
}

export default App;