import { createSlice } from '@reduxjs/toolkit'
import { reducers } from './reducers'

const initialState = {
  notification: [],
  showBalance: false
}

export const global = createSlice({
  name: 'global',
  initialState,
  reducers
})

// Action creators are generated for each case reducer function
export const { notify, toggleBalance } = global.actions

export default global.reducer