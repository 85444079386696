import React from "react";
import PropTypes from "prop-types"

export default function Home({ color }) {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.61847 17.3185V14.7628C7.61846 14.1152 8.14509 13.589 8.79739 13.5849H11.1925C11.8478 13.5849 12.3789 14.1123 12.3789 14.7628V14.7628V17.3111C12.3789 17.8728 12.8354 18.3293 13.4011 18.3334H15.0352C15.7984 18.3354 16.531 18.0357 17.0713 17.5007C17.6117 16.9656 17.9154 16.239 17.9154 15.4814V8.22162C17.9153 7.60957 17.6421 7.02901 17.1692 6.63633L11.6178 2.22864C10.6475 1.45768 9.26151 1.48259 8.31986 2.2879L2.88787 6.63633C2.39265 7.01743 2.09666 7.59972 2.08203 8.22162V15.4739C2.08203 17.0532 3.37152 18.3334 4.96218 18.3334H6.55894C6.83134 18.3354 7.09327 18.2293 7.28659 18.0388C7.47991 17.8482 7.58864 17.589 7.58863 17.3185H7.61847Z" fill={color || "#060628"}/>
        </svg>
    )
}

Home.propTypes = {
    color: PropTypes.string
};