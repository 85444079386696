import { createSlice } from '@reduxjs/toolkit'
import { extraReducers } from './reducers'

const initialState = {
    billerCategory: [],
    loading: false,
    error: null
}

export const utility = createSlice({
    name: 'utility',
    initialState,
    extraReducers
})

export default utility.reducer