import React from "react";

export default function Recycle() {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5190_53483)">
            <path d="M17.7696 5.60255L17.7696 5.60257C17.6236 5.7487 17.4053 5.79414 17.2136 5.71917L14.1748 5.61882L14.1748 5.61882L14.1764 5.56885C13.9176 5.5605 13.7148 5.3437 13.7233 5.08501C13.7318 4.82617 13.9485 4.62329 14.2072 4.63193L17.7696 5.60255ZM17.7696 5.60255C17.9154 5.45642 17.9605 5.23806 17.8853 5.0464L17.7852 2.00888C17.7852 2.00882 17.7852 2.00877 17.7852 2.00871C17.7787 1.72234 17.5412 1.4955 17.2549 1.50182L17.2548 1.50182C16.9686 1.5083 16.7413 1.74575 16.7478 2.03209L16.7978 2.03096M17.7696 5.60255L14.2088 4.58196M14.2088 4.58196L16.2963 4.65082C15.678 3.95341 14.9569 3.35416 14.158 2.87346C12.8469 2.08662 11.3801 1.67109 9.91621 1.67109C7.57446 1.67109 5.41571 2.63734 3.83633 4.3919C2.42766 5.95691 1.5876 8.05416 1.5876 9.99971C1.5876 10.2861 1.35546 10.5185 1.06885 10.5185C0.782239 10.5185 0.550098 10.2861 0.550098 9.99971C0.550098 7.80219 1.49077 5.44718 3.06518 3.69775C4.84324 1.72207 7.27692 0.633594 9.91621 0.633594C11.5689 0.633594 13.2203 1.1006 14.6918 1.98354L14.6919 1.98359C15.4663 2.45003 16.1757 3.01687 16.8017 3.6689L16.7481 2.04386C16.7478 2.03889 16.7478 2.0344 16.7478 2.03147V2.03096H16.7978M14.2088 4.58196C14.2088 4.58196 14.2088 4.58196 14.2088 4.58196L14.2088 4.58196ZM16.7978 2.03096C16.7919 1.77227 16.9973 1.55767 17.256 1.55181L16.798 2.04106C16.7978 2.0377 16.7978 2.03447 16.7978 2.03096Z" fill="#5034C4" stroke="#5034C4" strokeWidth="0.1"/>
            <path d="M3.65398 15.349C4.27233 16.0463 4.99342 16.6456 5.79234 17.1264C7.10341 17.9132 8.57023 18.3285 10.0341 18.3285C12.3758 18.3285 14.5346 17.3625 16.114 15.608C17.5226 14.0428 18.3627 11.9454 18.3627 10C18.3627 9.7134 18.5949 9.48125 18.8815 9.48125C19.1681 9.48125 19.4002 9.7134 19.4002 10C19.4002 12.1977 18.4595 14.5527 16.8851 16.3022C15.1071 18.2779 12.6734 19.3663 10.0341 19.3663C8.3814 19.3663 6.73003 18.8994 5.25846 18.0163L5.25838 18.0163C4.48397 17.5495 3.77462 16.983 3.14856 16.3308L3.20219 17.9559L3.65398 15.349ZM3.65398 15.349L5.74147 15.4179L5.74151 15.4179C6.02777 15.4271 6.26753 15.2026 6.27693 14.9163C6.28634 14.6301 6.06197 14.3903 5.77555 14.3807L5.77553 14.3807L2.73672 14.2805C2.54501 14.2056 2.32666 14.2512 2.18072 14.3971L2.18068 14.3972C2.03487 14.5433 1.98975 14.7615 2.06502 14.9532L2.16506 17.9907C2.16506 17.9908 2.16506 17.9909 2.16506 17.9909C2.17169 18.2774 2.40907 18.5044 2.69545 18.4979M3.65398 15.349L3.20254 17.968C3.20883 18.2543 2.98163 18.4916 2.69545 18.4979M2.69545 18.4979L2.69434 18.4479L2.69547 18.4979C2.69547 18.4979 2.69546 18.4979 2.69545 18.4979Z" fill="#5034C4" stroke="#5034C4" strokeWidth="0.1"/>
            </g>
            <defs>
            <clipPath id="clip0_5190_53483">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}