import React from 'react'
import PropTypes from 'prop-types'

export default function Header({ title, description, className, variant }) {
    return(
        <div className="mb-8 sm:mb-10">
            <div>
                {
                    variant === 'form' && <h1 className={`font-dmsans_m text-24 capitalize ${className}`}>{title}</h1>
                }
                { 
                    variant === 'modal' &&
                    <h2 className={`font-dmsans_m text-16 sm:text-24 text-center sm:text-start ${className}`}>
                        {title}
                    </h2>
                }
                {
                    description && 
                    <p className="text-16 font-dmsans_r font-normal leading-24 text-grey mt-2">
                        {description}
                    </p>
                }
            </div>
        </div>
    )
}

Header.propTypes ={
    title: PropTypes.string,
    description: PropTypes.any,
    className: PropTypes.string,
    variant: PropTypes.string
}