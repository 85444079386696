import { getCardTransactionHistory, getVirtualCardBalance, getVirtualCardDetails, getVirtualCards } from "./actions";

const reducers = {
    resetCards: (state) => {
        state.cards = undefined;
    }
};

const extraReducers = {
    // get virtual card balance
    [getVirtualCardBalance.fulfilled]: (state, action) => {
        if (action?.payload?.data) state.virtualCardBalance = action?.payload?.data
    },
    // get virtual card details
    [getVirtualCardDetails.fulfilled]: (state, action) => {
        if (action?.payload?.data) state.getVirtualCardDetails = action?.payload?.data
    },
    // get virtual card history
    [getCardTransactionHistory.fulfilled]: (state, action) => {
        if (action?.payload?.data) state.cardTransactionHistory = action?.payload?.data
    },
    [getVirtualCards.fulfilled]: (state, action) => {
        if (action?.payload?.data) state.cards = action?.payload?.data
    },
};

export { extraReducers, reducers };
